import * as Routes from '@/modules/routes';
import { Program, SectionType } from '@/types/api/LessonOverview';

export const readingPath = (section: SectionType | null, program: Program, sections: SectionType[]) => {
  const sectionToUse = section ?? sections.filter(s => s.kind === 'default')[0];

  if (sectionToUse == null) return '#';

  return (
    Routes.shared_section_path(sectionToUse.id, { program_id: program.id })
  );
};

export const sectionsWithLessonVideos = (sections: SectionType[]): { [id: string]: SectionType } => {
  return sections.reduce((acc, section) => {
    if (section.modelVideos.length > 0) acc[section.id] = section;

    return acc;
  }, {});
};

export const getSectionsByKind = (sections: SectionType[]) => {
  const sectionsByKind = {};

  sections.forEach((section) => {
    if (sectionsWithLessonVideos(sections)[section.id]) return;

    sectionsByKind[section.kind] = [...(sectionsByKind[section.kind] || []), section];
  });

  return sectionsByKind;
};
