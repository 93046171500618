import React from 'react';
import I18n from 'i18n-js';
import DottedLine from '@/components/shared/NewLessonOverview/Header/DottedLine';
import useLessonOverviewContext from '@/components/shared/NewLessonOverview/useLessonOverviewContext';
import pillHeader from 'images/icons/pill_header.svg';

const Header = () => {
  const lesson = useLessonOverviewContext(state => state.lesson);
  const unitColor = useLessonOverviewContext(state => state.unitColor);
  const lessonLabel = I18n.t('lesson');

  return (
    <div className="tw-py-4">
      <div
        className="tw-w-40 tw-relative tw-bg-no-repeat tw--left-8 tw-h-7 tw-align-middle"
        style={{ backgroundImage: `url(${pillHeader})` }}
      >
        <p
          className="tw-relative tw-text-lg tw-font-semibold tw-left-16 tw-p-0 tw-leading-7"
          style={{ color: `#${unitColor}` }}
        >
          {lessonLabel} {lesson.titleNumber}
        </p>
      </div>
      <p className="tw-font-bold tw-text-2xl tw-mx-8 tw-mt-1 tw-mb-4">
        {lesson.title}
      </p>
      <DottedLine className="tw-mx-8" color={unitColor} />
    </div>
  );
};

export default Header;
