import React from 'react';
import Spinner from '@/components/common/Spinner';
import useProgramContent from './useProgramContent';
import useProgramContentStore from './useProgramContentStore';
import UnitInputs from './UnitInputs';
import LessonInputs from './LessonInputs';

interface ReuseProgramContentProps {
  programId: number;
}

const ReuseProgramContent = ({ programId }: ReuseProgramContentProps) => {
  const { isLoading, units, lessons } = useProgramContent({ programId });
  const allLessonsChecked = useProgramContentStore(state => state.allLessonsChecked);
  const toggleAllLessons = useProgramContentStore(state => state.toggleAllLessons);
  const toggleLessonOn = useProgramContentStore(state => state.toggleLessonOn);
  const toggleLessonOff = useProgramContentStore(state => state.toggleLessonOff);

  const renderProgramContent = () => {
    if (isLoading) return <Spinner />;

    if (units.length > 0) {
      return units.map(unit => (
        <div key={`unit-${unit.id}`}>
          <UnitInputs unit={unit} />
          <div className="tw-ml-5">
            {unit.lessons.map(lesson => (
              <LessonInputs
                key={`lesson-inputs-${lesson.id}`}
                lesson={lesson}
              />
            ))}
          </div>
        </div>
      ));
    }

    return lessons.map(lesson => (
      <LessonInputs
        key={`lesson-inputs-${lesson.id}`}
        lesson={lesson}
      />
    ));
  };

  const allUnitsChecked = () => units.every(unit => allLessonsChecked(unit.lessons.map(l => l.id)));

  const onCheck = () => {
    if (units.length > 0) {
      if (allUnitsChecked()) {
        units.forEach((unit) => {
          unit.lessons.forEach(lesson => toggleLessonOff(lesson.id));
        });
      } else {
        units.forEach((unit) => {
          unit.lessons.forEach(lesson => toggleLessonOn(lesson.id));
        });
      }
    } else toggleAllLessons(lessons.map(l => l.id));
  };

  const isChecked = () => {
    if (units.length > 0) return allUnitsChecked();

    return allLessonsChecked(lessons.map(l => l.id));
  };

  return (
    <div className="tw-mt-2 tw-max-w-[800px]">
      <span className="tw-text-lg tw-font-bold tw-mb-1">
        {(isLoading || units.length) ? 'Units and Lessons' : 'Lessons'}
      </span>

      <label className="tw-flex tw-items-center tw-mb-4">
        <input
          checked={isChecked()}
          type="checkbox"
          value="select_all"
          onChange={onCheck}
        />
        <span className="tw-ml-1">Select All</span>
      </label>

      {renderProgramContent()}
    </div>
  );
};

export default ReuseProgramContent;
