import React from 'react';
import * as Routes from '@/modules/routes';
import useLessonOverviewContext from '@/components/shared/NewLessonOverview/useLessonOverviewContext';
import readingIcon from 'images/icons/reading.svg';
import calendarIcon from 'images/icons/calendar-plus.svg';
import { IReading } from '../types';
import { readingLabel } from '../DiveDeeper.utils';

type CardProps = {
  reading? : IReading;
  openAssign: () => void;
};

const ReadingCard = ({ openAssign, reading } : CardProps) => {
  const programId = useLessonOverviewContext(state => state.programId);
  const readingPath = Routes.shared_section_path(reading.id, { program_id: programId });

  return (
    <>
      <div className="tw-mb-[1rem] tw-border tw-border-solid tw-border-med-grey tw-shadow-lg tw-w-full tw-rounded-lg
        tw-h-[4.5rem] tw-flex tw-justify-start tw-items-center tw-bg-white"
      >
        <div className="tw-flex tw-justify-center tw-items-center tw-rounded-lg tw-bg-blue tw-min-h-[2.5rem]
          tw-min-w-[2.5rem] tw-mx-[1rem]"
        >
          <img alt="book-icon" className="tw-invert" src={readingIcon} />
        </div>
        <a
          className="tw-text-[16px] tw-mb-0 tw-max-h-[80%] tw-text-tci-purple tw-w-[67%] tw-mr-auto
          tw-text-ellipsis tw-overflow-hidden"
          href={readingPath}
        >
          {`${readingLabel(reading.kind)} : ${reading.subtitle}`}
        </a>
        {!reading.isAssigned && (
        <button
          className="tw-bg-transparent tw-border-none focus:tw-border-none active:tw-border-none tw-cursor-pointer
          focus:tw-outline-none"
          type="button"
          onClick={openAssign}
        >
          <img
            alt="calendar-plus icon"
            className="tw-mr-[1rem]"
            src={calendarIcon}
          />
        </button>
        )}
      </div>
    </>
  );
};
export default ReadingCard;
