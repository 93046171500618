import React from 'react';
import I18n from 'i18n-js';
import * as Routes from '@/modules/routes';
import useLessonOverviewContext from '@/components/shared/NewLessonOverview/useLessonOverviewContext';
import assessmentsIcon from 'images/icons/assessments.svg';
import printIcon from 'images/icons/printK5.svg';
import ActionBarLink from './ActionBar/ActionBarButtons/ActionBarLink';
import Card from './Card';
import { CardBody } from './Card/Card';
import ActionBar from './ActionBar';

type AssessmentsCardProps = {
  type: string,
};

const AssessmentsCard = ({ type }: AssessmentsCardProps) => {
  const lesson = useLessonOverviewContext(state => state.lesson);
  const programId = useLessonOverviewContext(state => state.programId);
  const hasAssessments = useLessonOverviewContext(state => state.hasAssessments);
  const isStaffer = useLessonOverviewContext(state => state.isStaffer);
  const isAssigned = lesson.assessments.some(assessment => assessment.isAssigned);
  const cardLabel = I18n.locale === 'en' ? `TCI ${I18n.t('assessments')}` : `${I18n.t('assessments')} TCI`;

  const printHref = () => {
    if (!hasAssessments) return '';

    return Routes.pdf_staffer_assessment_print_path(lesson.assessments[0].id, {
      locale: I18n.locale,
      program_id: programId,
    });
  };

  // If there is at least one assessment that can be assigned, return the id of the first one.
  const assessmentToAssign = hasAssessments ? {
    label: lesson.assessments[0].name,
    value: `Assessment-${lesson.assessments[0].id}`,
  } : {
    label: '',
    value: '',
  };

  return (
    <Card label="assessments" type={type}>
      <CardBody
        ariaLabel={cardLabel}
        iconSrc={assessmentsIcon}
        linkHref={Routes.shared_program_lesson_assessments_path(programId, lesson.id)}
        linkLabel={cardLabel}
        topLabel={I18n.t(type)}
      />
      {isStaffer && (
        <ActionBar
          href={Routes.shared_program_lesson_assessments_path(programId, lesson.id)}
          iconType="arrow"
          initialValues={{
            modelType: 'assessment',
            modelsToAssign: [assessmentToAssign],
          }}
          isAssigned={isAssigned}
          label={cardLabel}
        >
          {hasAssessments && (
            <ActionBarLink
              href={printHref()}
              icon={printIcon}
              label={I18n.t('print_assessment')}
              tag="print"
            />
          )}
        </ActionBar>
      )}
    </Card>
  );
};

export default AssessmentsCard;
