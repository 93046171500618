import React from 'react';

interface ContentRadioProps {
  checked: boolean;
  value: 'copy' | 'reuse' | 'doNotUse';
  onChange: () => void;
}

const LABELS = {
  copy: 'Copy',
  reuse: 'Reuse',
  doNotUse: 'Do Not Use',
};

const ContentRadio = ({ checked, value, onChange }: ContentRadioProps) => (
  <label
    className="tw-flex tw-items-center tw-mb-2 tw-ml-2"
  >
    <input
      checked={checked}
      type="radio"
      value={value}
      onChange={onChange}
    />
    <span>{LABELS[value]}</span>
  </label>
);

export default ContentRadio;
