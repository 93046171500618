import React, { useEffect } from 'react';
import Axios from 'axios';
import * as Routes from '@/modules/routes';
import { useQuery } from '@tanstack/react-query';
import useAssignablesStore from '../store/useAssignablesStore';

interface PopulateStoreProps {
  form: () => any;
  formChangeValue: (newValue: Array<string> | string, formValueName: string) => void;
  initialValues: {
    gradingNotebookBy: string | null,
    modelType: string | null,
    modelsToAssign: [{ label: string, value: string }] | [],
    randomizeAnswers: boolean | null,
    readingAssignableSelections: [string] | [],
    slideShowSelect: string | null,
  };
  programId: number;
  stafferId: number;
}

export const PopulateStore = ({ form, formChangeValue, initialValues, programId, stafferId } : PopulateStoreProps) => {
  const setStore = useAssignablesStore(state => state.setStore);

  useEffect(() => {
    if (!initialValues) return;
    if (!initialValues.modelType
      || (!initialValues.modelsToAssign?.length && !initialValues.readingAssignableSelections?.length)) return;

    setStore({ initialValues });
  }, [initialValues.modelType, initialValues.modelsToAssign, initialValues.readingAssignableSelections]);

  useEffect(() => {
    setStore({ formChangeValue });
  }, [formChangeValue]);

  useEffect(() => {
    setStore({ form });
  }, [form]);

  const { isFetching } = useQuery(['fetchAssignmentsData'], () => {
    Axios.get(Routes.plato_api_program_path(programId,
      { params: { only: ['has_units', 'science', 'middle_school'] } }))
      .then((res) => {
        const programParams = res.data.program;

        const newState = {
          hasUnits: programParams.has_units,
          isFetching,
          isScience: programParams.science,
          isMiddleSchoolProgram: programParams.middle_school,
          programId,
          stafferId,
        };

        setStore(newState);
      });

    return '';
  });

  return (
    <></>
  );
};
