import React from 'react';
import HeaderNumberBox from './Header/HeaderNumberBox';

type LessonSubheaderProps = {
  children: ReactChildren,
  description?: string,
  subheaderNumber?: number;
  title: string,
};

const LessonSubheader = ({
  children,
  subheaderNumber,
  title,
}: LessonSubheaderProps) => !!React.Children.count(children) && (
  <div className="tw-ml-8 tw-mb-8 tw-relative">
    <h2
      aria-label={title}
      className="tw-my-2 tw-text-2xl tw-align-middle tw-leading-8"
    >
      {subheaderNumber && (
        <HeaderNumberBox number={subheaderNumber} />
      )}
      <span>{title}</span>
    </h2>
    <div className="tw-flex tw-flex-wrap tw-w-full">
      {children}
    </div>
  </div>
);

export default LessonSubheader;
