import React, { Fragment, Component } from 'react';
import { renderToString } from 'react-dom/server';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import axios from 'axios';
import Cookie from 'react-cookies';
import moment from 'moment-timezone';
import I18n from 'i18n';
import tippy from 'modules/TciTippy';
import { decode } from 'html-entities';
import * as Routes from 'routes';
import { redirectLinks } from '../../../modules/ExternalRedirect';
import ActionsDropdown from 'common/ActionsDropdown';
import AdvancedFilters from 'common/AdvancedFilters';
import { buildFilterPath, buildQueryPath, filtersPropTypes } from 'common/AdvancedFilters/Utils';
import PaginatedTable from 'common/DataTable/PaginatedTable';
import Tooltip from 'common/Tooltip/index';
import googleClassroomLogo from 'images/icons/google_class_connected.png';
import canvasLogo from 'images/icons/canvas-connected.png';
import schoologyLogo from 'images/icons/schoology-connected.svg';
import AssignmentModal from 'staffer/AssignmentModal';
import { assessmentsPropType, classroomsPropType } from 'staffer/AssignmentModal/PropTypes';
import useAssignablesStore from 'staffer/AssignmentModal/WhatSection/store/useAssignablesStore';
import Add from './Add';
import Delete from './Delete';
import Edit from './Edit';
import SyncGrades from './SyncGrades';
import ViewSyncActivity from './ViewSyncActivity';
import styles from './AssignmentsTable.module.scss';
import { formatAssignments, formattedAssignment } from './Utils';
import AssignmentDetails, { ASSIGNMENT_DETAILS_TOOLTIP_CLASS } from './AssignmentDetails';
import AssignmentFilterButton from './AssignmentFilterButton';
import SettingsShortcut from 'common/ShortcutButtons/SettingsShortcut';

export default class AssignmentsTable extends Component {
  static propTypes = {
    assessments: assessmentsPropType,
    assessmentsPagePath: PropTypes.string,
    assignmentAssignmentsModelsPath: PropTypes.string, // staffer
    assignmentCounts: PropTypes.shape({
      all: PropTypes.number,
      completed: PropTypes.number,
      pastDue: PropTypes.number,
      toDo: PropTypes.number
    }),
    assignmentsHash: PropTypes.any,
    assignmentsPath: PropTypes.string,
    classroomId: PropTypes.number,
    classrooms: classroomsPropType,
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    connectedToCanvas: PropTypes.bool,
    connectedToCanvas13: PropTypes.bool,
    connectedToGoogleClassroom: PropTypes.bool,
    defaultExpandRows: PropTypes.bool,
    destroyPath: PropTypes.string,
    hideButtons: PropTypes.bool,
    inDashboard: PropTypes.bool,
    info: PropTypes.bool,
    initialFilters: filtersPropTypes,
    lastViewed: PropTypes.string,
    lessonAutofillOptions: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })),
    pageLength: PropTypes.number,
    pageSize: PropTypes.number,
    paging: PropTypes.bool,
    program: PropTypes.shape({
      display_investigations_text_section: PropTypes.bool.isRequired,
      display_unit_content: PropTypes.bool.isRequired,
      hasVideoActivity: PropTypes.bool.isRequired,
      id: PropTypes.number.isRequired,
      science: PropTypes.bool.isRequired
    }).isRequired,
    retroactiveGradeSync: PropTypes.bool,
    setAssignments: PropTypes.func,
    showPagingOptions: PropTypes.bool,
    studentId: PropTypes.number,
    subscriberConnectedToCanvas: PropTypes.bool,
    updatePath: PropTypes.string,
    userId: PropTypes.number.isRequired,
    userType: PropTypes.string,
  };

  static defaultProps = {
    assessments: [],
    assessmentsPagePath: '',
    assignmentAssignmentsModelsPath: '',
    assignmentCounts: {
      all: null,
      completed: null,
      pastDue: null,
      toDo: null
    },
    assignmentsPath: '',
    classroomId: null,
    classrooms: [],
    connectedToCanvas: false,
    connectedToCanvas13: false,
    connectedToGoogleClassroom: false,
    defaultExpandRows: true,
    destroyPath: '',
    hideButtons: false,
    inDashboard: false,
    info: true,
    initialFilters: null,
    lastViewed: null,
    lessonAutofillOptions: null,
    pageLength: 20,
    pageSize: 20,
    paging: true,
    retroactiveGradeSync: false,
    setAssignments: () => {},
    showPagingOptions: true,
    subscriberConnectedToCanvas: false,
    studentId: null,
    updatePath: '',
    userType: null,
  };

  static formatAssignmentsModelsDropdown(assignmentsModels, className) {
    if (!assignmentsModels) return null;
    return assignmentsModels.map(model => (
      <li key={`${model.label} ${model.href}`} className={`${styles['dropdown-item']} ${className} ${styles.listItem}`}>
        <a
          href={model.href}
          rel="noopener noreferrer"
          target="_blank"
        >
          {model.label}
        </a>
      </li>
    ));
  }

  static showLock(datesEnforced) {
    if (datesEnforced) {
      return (
        <span className="ml10">
          <Tooltip
            content="This assignment will be locked after the due date."
          >
            <i className="fa fa-lock" />
          </Tooltip>
        </span>
      );
    }

    return null;
  }

  static classroomDiv(classroom) {
    return (
      <div key={classroom.id}>
        Period {classroom.period} - {classroom.name}
      </div>
    );
  }

  constructor(props) {
    super(props);
    let isCollapsed = Cookie.load('isCollapsed');
    // TODO: had to remove the lesson assoc ({ assignments_models: { model: 'lesson' } })
    // because of the state capitals game. leaving a comment for visibility in case
    // performance seems to be a concern
    const includes = ['assignments_models', 'program', 'classrooms'];

    if (props.studentId) {
      this.getPathBase = Routes.plato_api_student_index_student_assignments_path(
        props.userId,
        {
          for_classroom: props.classroomId,
          in_program: props.program.id,
          includes,
          page: 'pageNumber',
          per_page: 20
        }
      );
    }
    else {
      this.getPathBase = Routes.plato_api_staffer_assignments_path(
        props.userId,
        {
          in_program: props.program.id,
          includes,
          local_time_zone: moment.tz.guess(),
          page: 'pageNumber',
          per_page: 20
        }
      );
    }

    const params = new URLSearchParams(window.location.search);
    const activeToggle = params.get('activeToggle');
    if (this.props.studentId) {
      this.state = {
        activeFilters: {},
        activeToggle: activeToggle || 'to_do',
        doneLoading: false,
        loadingInitialValues: false,
        filterSent: false,
        getPath: this._initialGetPath(),
        initialLessonId: null,
        initialValues: {},
        isCollapsed: false,
        modalIsOpen: false,
        newRecord: true,
        pagesLoaded: 0,
        resources: []
      };
    }
    else {
      this.state = {
        activeFilters: {},
        activeToggle: activeToggle || 'all',
        doneLoading: false,
        loadingInitialValues: false,
        filterSent: false,
        getPath: this._initialGetPath(),
        initialLessonId: null,
        initialValues: {},
        isCollapsed: isCollapsed ? (isCollapsed === '1' ? true : false) : true,
        modalIsOpen: false,
        newRecord: true,
        pagesLoaded: 0,
        resources: []
      };
    }

    this.closeAssignmentModal = this.closeAssignmentModal.bind(this);
    this.openAssignmentModal = this.openAssignmentModal.bind(this);
    this.getAssignmentsModels = this.getAssignmentsModels.bind(this);
    this.renderActionsDropdown = this.renderActionsDropdown.bind(this);
    this.updateResources = this.updateResources.bind(this);
    this._updateActiveFilters = this._updateActiveFilters.bind(this);
    this._addResources = this._addResources.bind(this);
    this._resetResources = this._resetResources.bind(this);
    this.renderDatesEnforcedCell = this.renderDatesEnforcedCell.bind(this);
    this.renderClassColumn = this.renderClassColumn.bind(this);
    this.formatLabel = this.formatLabel.bind(this);
    this.formatClasses = this.formatClasses.bind(this);
    this.renderClassesCell = this.renderClassesCell.bind(this);
    this.tooltipContent = this.tooltipContent.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  _initialGetPath() {
    if (this.props.studentId) return buildQueryPath(this.getPathBase, {});

    const initialFilterHash = {};

    // setting up initial filters hash here because leaving it to advanced filters failed
    if (this.getInitialFilters()) {
      this.getInitialFilters().map((filter) => {
        if (filter.value) {
          initialFilterHash[`${filter.propertyValue}_${filter.operatorValue}`] = filter.value;
        }

        return null;
      });
    }

    return buildFilterPath(this.getPathBase, initialFilterHash);
  }

  tooltipContent(classes) {
    const values = this.props.classrooms.filter(classroom => classes.map(c => c.id).includes(classroom.id));

    return (
      <div>
        {values.map(classroom => AssignmentsTable.classroomDiv(classroom))}
      </div>
    );
  }

  formatClasses(classes) {
    if (classes.length === 0) return <span>None</span>;
    const classesLength = this.props.classrooms.length === classes.length ? 'All' : classes.length;

    return (
      <Tooltip
        interactive={false}
        content={this.tooltipContent(classes)}
        theme="white"
        size="medium"
      >
        <span>{classesLength}</span>
      </Tooltip>
    );
  }

  renderClassesCell(td, cellData, rowData) {
    ReactDOM.render(
      this.formatClasses(rowData.classes),
      td
    );
  }

  renderClassColumn(data, type, row) {
    if (row.classes.length === 0) return renderToString(<span>None</span>);

    const classroomNames = row.classes.map(classroom => classroom.name).sort();
    return renderToString(<span>{decode(classroomNames.join(', '))}</span>);
  }

  formatLabel(data, type, row) {
    return renderToString(
      <div data-assignment-id={row.id}>
        <div>
          {this.props.studentId && this.renderViewedStatus(row)}
        </div>
        <div>
          <p className={styles.location}>
            {row.assignment_location}
          </p>
          <div className={styles.assignmentTitle}>
            {row.assignment_label}
          </div>
        </div>

        {this.renderIcon(row)}
      </div>
    );
  }

  showCanvasLogo(row) {
    const connectedToCanvasApi = row.lms_type === 'canvas' &&
      this.props.connectedToCanvas &&
      this.props.subscriberConnectedToCanvas;
    const connectedToCanvas13 = row.lms_type === 'LTI' && this.props.connectedToCanvas13;

    return connectedToCanvasApi || connectedToCanvas13;
  }

  renderIcon(row) {
    if (this.props.studentId) return null;

    const showGoogleClassroomLogo = row.connected_to_google;
    const showSchoologyLogo = row.connected_to_schoology;

    return (
      <>
        {showGoogleClassroomLogo && (
          <img width="30" className="ml10" alt="Connected to Google" src={googleClassroomLogo} />
        )}
        {this.showCanvasLogo(row) && <img width="30" className="ml10" alt="Connected to Canvas" src={canvasLogo} />}
        {showSchoologyLogo && <img width="30" className="ml10" alt="Connected to Schoology" src={schoologyLogo} />}
      </>
    );
  }

  /**
   * Get assignments_models for a given assignment id.
   * @param assignmentId
   * @returns {Promise<AxiosResponse<any> | void>}
   */
  getAssignmentsModels(assignmentId, unitLessonId = undefined) {
    const params = {
      assignment_id: assignmentId,
      parent_id: unitLessonId,
      program_id: this.props.program.id
    };

    return axios.get(Routes.plato_api_get_initial_assignables_assignables_path(params))
      .then((response) => {
        return response.data;
      })
      .catch(e => console.log(e));
  }

  static getStudentIdsInClassroom(classroomIds) {
    if (classroomIds.length > 1) return [];

    return axios
      .get(Routes.plato_api_classroom_students_path(classroomIds[0]))
      .then((response) => {
        const students = response.data.data;

        return students.map(student => student.id);
      });
  }

  static getClassroomAssigneeModels(assignmentId, classroomAssignment) {
    return axios
      .get(Routes.plato_api_assignment_assignments_assignee_models_path(assignmentId))
      .then((response) => {
        const assignmentsAssigneeModels = response.data.data;

        if (classroomAssignment) {
          const classroomIds = assignmentsAssigneeModels.map(assigneeModel => assigneeModel.model_id);

          return {
            assignee_model_ids: classroomIds,
            classroomAssignment: classroomAssignment,
            classroom_student_ids: [], // ids of students in the assigned classroom
            student_classroom_ids: [] // ids of classrooms that the assignment was assigned to (only populated for individual student assignments)
          };
        }

        return {
          assignee_model_ids: [],
          classroom_student_ids: [],
          student_classroom_ids: [...new Set(assignmentsAssigneeModels.map(assigneeModel => assigneeModel.classroom_id))]
        };
      })
      .then((values) => {
        // if the assignment has only been assigned to one classroom, then we aso get the ids of all the students in the classroom
        if (values.assignee_model_ids.length === 1 && values.classroomAssignment) {
          return AssignmentsTable.getStudentIdsInClassroom(values.assignee_model_ids)
            .then(studentIds => Object.assign({}, values, { classroom_student_ids: studentIds }));
        }

        return values;
      })
      .catch(e => console.log(e));
  }

  static getStudentAssigneeModels(assignmentId) {
    return axios
      .get(Routes.plato_api_assignment_assignments_assignee_models_path(assignmentId),
        { params: { in_classroom_for_assignment: assignmentId } })
      .then((response) => {
        const assignmentsAssigneeModels = response.data.data;

        if (assignmentsAssigneeModels.length > 0) {
          // we return all the ids of the students the assignment has been assigned to
          // along with the id of the classroom the assignment has been assigned to
          return {
            assignee_model_ids: assignmentsAssigneeModels.map(assigneeModel => assigneeModel.model_id),
            student_classroom_ids: [assignmentsAssigneeModels[0].classroom_id]
          };
        }

        return {
          assignee_model_ids: [],
          student_classroom_ids: []
        };
      })
      .catch(e => console.log(e));
  }

  static getClassroomsAndAssignees(rowData) {
    // if an assignment has been assigned to all students, it's a classroom assignment
    if (rowData.students === 'All') {
      return AssignmentsTable.getClassroomAssigneeModels(rowData.id, rowData.classroom_assignment);
    }

    return AssignmentsTable.getStudentAssigneeModels(rowData.id);
  }

  /**
   * Create initial values object for an existing assignment.
   * @param rowData
   * @returns {null|{
   *   assignment_id:, due_datetime, assessment_ids:, lesson_assignable_ids:,
   *   student_classroom_ids:, assignee_model_ids:
   * }}
   */
  getInitialValues(rowData) {
    if (!rowData) return null;

    const unitLessonId = rowData.first_lesson_id || rowData.first_unit_id;

    this.setState({
      initialLessonId: unitLessonId,
      loadingInitialValues: true
    });

    // values for What/When section (assessment_ids, lesson_assignable_ids)
    return this
      .getAssignmentsModels(rowData.id, unitLessonId)
      .then((assignables) => {
        const initialValues = {
          assignment_id: rowData.id,
          dates_enforced: rowData.dates_enforced,
          due_datetime: rowData.due_datetime,
          grading_notebook_by: rowData.grading_notebook_by,
          name: rowData.name,
          notes: rowData.notes,
          randomize_answers: rowData.randomize_answers,
          start_datetime: rowData.start_datetime,
        };

        return Object.assign(initialValues, assignables);
      })
      .then((values) => {
        // values for Who section (student_classroom_ids, assignee_model_ids)
        AssignmentsTable.getClassroomsAndAssignees(rowData)
          .then((classroomsAndAssignees) => {
            const initialValues = Object.assign({}, values, classroomsAndAssignees);
            this.setState({ initialValues, loadingInitialValues: false });
            return initialValues;
          })
          .catch(() => this.setState({ loadingInitialValues: false }));
      })
      .catch(() => this.setState({ loadingInitialValues: false }));
  }

  /**
   * Returns the filters passed in if there are any, otherwise returns the default filters.
   * This done here so that we can pass in a local timestamp for the user.
   * @returns {array} - an array of objects representing the initial filters
   */
  getInitialFilters() {
    if (this.props.studentId) return null;

    if (this.props.initialFilters) {
      return this.props.initialFilters;
    }

    return [
      { operatorValue: 'gteq', propertyValue: 'due_datetime' },
      { operatorValue: 'eq', propertyValue: 'assignments_models_lesson_id' }
    ];
  }

  openAssignmentModal(newRecord, rowData = null) {
    this.getInitialValues(rowData);
    this.setState({ modalIsOpen: true, newRecord });
  }

  closeAssignmentModal() {
    this.setState({ initialLessonId: null, initialValues: {}, modalIsOpen: false });
    useAssignablesStore.getState().resetState();
  }

  /**
   * Used to update resources after creation of assignments
   * @param data Serialized assignments from response
   * @param method is the action being performed
   */
  updateResources(data, method) {
    if (method === 'create') {
      this.addResource(data);
    }
    else if (method === 'update') {
      this.editResource(data);
    }
    else if (method === 'delete') {
      this.deleteResource(data);
    }
  }

  addResource(data) {
    const assignment = formattedAssignment(data);

    const resources = [...this.state.resources];
    resources.push(assignment);
    this.setState({ resources });
  }

  editResource(data) {
    const resources = this.state.resources.filter(assignment => assignment.id !== data.id);
    const updatedAssignment = formattedAssignment(data);
    resources.push(updatedAssignment);
    this.setState({ resources });
  }

  deleteResource(data) {
    const resources = this.state.resources.filter(assignment => assignment.id !== data.id);
    const state = { resources };
    state.resources = resources;
    this.setState(state);
  }

  columnDefs() {
    const toggleIcon = '<button class="no_button_actions"><i class="fa toggle-icon purple" /></button>';

    if (this.props.studentId) {
      return (
        [
          {
            ariaTitle: 'Expand/Collapse Assignment Name',
            className: styles.expandCollapseWrapper,
            render: () => toggleIcon,
            sortable: false,
            targets: 0,
            title: '.',
            width: '2%'
          },
          {
            render: this.formatLabel,
            targets: 1,
            title: I18n.t('assignment_name'),
            width: '30%'
          },
          {
            targets: 2,
            title: I18n.t('start_date'),
            type: 'date',
            width: '15%'
          },
          {
            targets: 3,
            title: I18n.t('due_date'),
            type: 'date',
            width: '15%',
            createdCell: this.renderDatesEnforcedCell
          }
        ]
      );
    }

    return (
      [
        {
          className: styles.expandCollapseWrapper,
          targets: 0,
          title: '.',
          ariaTitle: 'Expand/Collapse Assignment Name',
          sortable: false,
          render: () => toggleIcon
        },
        {
          targets: 2,
          title: I18n.t('class'),
          createdCell: this.renderClassesCell,
          width: '15%'
        },
        { // Students
          targets: 3,
          title: I18n.t('students'),
          width: '10%',
        },
        {
          render: this.formatLabel,
          targets: 1,
          title: I18n.t('assignment_name'),
          width: '35%'
        },
        {
          targets: 4,
          title: I18n.t('start_date'),
          type: 'date'
        },
        {
          targets: -2,
          title: I18n.t('due_date'),
          type: 'date',
          createdCell: this.renderDatesEnforcedCell
        },
        { // Actions
          className: 'actions-column',
          title: I18n.t('actions'),
          targets: -1,
          sortable: false,
          width: '10%',
          searchable: false,
          createdCell: this.renderActionsDropdown
        }
      ]
    );
  }

  defaultOrder() {
    return this.props.studentId ? [3, 'asc'] : [5, 'desc'];
  }

  renderAdd() {
    if (this.props.studentId) return null;

    return <Add openAssignmentModal={this.openAssignmentModal} />;
  }

  renderAssignmentModal() {
    if (this.props.studentId) return null;

    return (
      <AssignmentModal
        assessments={this.props.assessments}
        assessmentsPagePath={this.props.assessmentsPagePath}
        assignmentsPath={this.props.assignmentsPath}
        classrooms={this.props.classrooms}
        closeModal={this.closeAssignmentModal}
        connectedToCanvas={this.props.connectedToCanvas}
        connectedToCanvas13={this.props.connectedToCanvas13}
        connectedToGoogleClassroom={this.props.connectedToGoogleClassroom}
        loadingInitialValues={this.state.loadingInitialValues}
        handlePostSave={this.updateResources}
        initialLessonId={this.state.initialLessonId}
        initialValues={this.state.initialValues}
        modalIsOpen={this.state.modalIsOpen}
        newRecord={this.state.newRecord}
        program={this.props.program}
        stafferId={this.props.userId}
        subscriberConnectedToCanvas={this.props.subscriberConnectedToCanvas}
        updatePath={this.props.updatePath}
      />
    );
  }

  renderActionsDropdown(td, cellData, rowData) {
    ReactDOM.render(
      this.renderDropdown(rowData),
      td
    );
  }

  renderGradebookOption(rowData) {
    if (rowData.assignment_models_hash &&
      Object.values(rowData.assignment_models_hash).flat().some(model => model.classroom_activity)) return;

    if (rowData.classes.length > 0) {
      return (
        <a
          className="dropdown-item"
          href={Routes.teacher_score_path(rowData.classes[0].id, {
            lesson_id: rowData.first_lesson_id,
            program_id: this.props.program.id,
            unit_id: rowData.first_unit_id,
          })}
          role="button"
        >
          <i className="fa fa-bar-chart" aria-hidden="true" />
          {' '}
          {I18n.t('grade')}
        </a>
      );
    }

    return (
      <Tooltip
        content="Cannot grade assignment assigned to no students."
        interactive={false}
      >
        <span
          className="dropdown-item link-disabled"
        >
          <i className="fa fa-bar-chart" aria-hidden="true" />
          {' '}
          {I18n.t('grade')}
        </span>
      </Tooltip>
    );
  }

  renderDropdown(rowData) {
    const showGoogleClassroomLogo = rowData.connected_to_google && !this.props.studentId;
    const showCanvasLogo = this.showCanvasLogo(rowData) && !this.props.studentId;
    const showSchoologyLogo = rowData.connected_to_schoology && !this.props.studentId;

    return (
      <ActionsDropdown>
        <Edit
          openAssignmentModal={this.openAssignmentModal}
          rowData={rowData}
        />

        {this.renderGradebookOption(rowData)}

        <Delete
          destroyPath={this.props.destroyPath}
          rowData={rowData}
          updateResources={this.updateResources}
        />

        {this.props.retroactiveGradeSync && (showGoogleClassroomLogo || showCanvasLogo || showSchoologyLogo) && (
          <>
            <hr />

            <SyncGrades
              assignmentId={rowData.id}
              showGoogleClassroomLogo={showGoogleClassroomLogo}
              showCanvasLogo={showCanvasLogo}
              showSchoologyLogo={showSchoologyLogo}
            />
          </>
        )}
        {(showGoogleClassroomLogo || showCanvasLogo || showSchoologyLogo) && this.props.userType === 'Sysadmin' && (
          <ViewSyncActivity
            assignmentId={rowData.id}
            showGoogleClassroomLogo={showGoogleClassroomLogo}
            showCanvasLogo={showCanvasLogo}
            showSchoologyLogo={showSchoologyLogo}
          />
        )}
      </ActionsDropdown>
    );
  }

  renderViewedStatus(rowData) {
    let viewedStatus;

    if (this.props.lastViewed) {
      const lastViewed = moment(this.props.lastViewed);

      if (moment(rowData.created_at) > lastViewed) {
        viewedStatus = 'New!';
      }
      else if (moment(rowData.updated_at) > lastViewed) {
        viewedStatus = 'Updated!';
      }
    }
    else {
      viewedStatus = 'New!';
    }

    return viewedStatus && <span className={styles.viewedStatus}>{viewedStatus}</span>;
  }

  renderDatesEnforcedCell(td, cellData, rowData) {
    ReactDOM.render(
      <Fragment>
        {cellData}
        {AssignmentsTable.showLock(rowData.dates_enforced)}
      </Fragment>,
      td
    );
  }

  assignmentInfo = rowData =>
    renderToString(AssignmentDetails({
      rowData: rowData,
      isStudent: this.props.studentId,
      science: this.props.program.science,
      displayUnitContent: this.props.program.display_unit_content
    }));

  assignmentInfoAfterInit(row) {
    tippy.default(
      `.${ASSIGNMENT_DETAILS_TOOLTIP_CLASS}`,
      { content: I18n.t('assignment_not_started') }
    );

    if (row) {
      const td = row.child().find('td');
      td?.addClass('no-top-border');
    }
  }

  _updateActiveFilters(activeFilters, activeToggle = null) {
    if (activeFilters) {
      this.setState(
        { activeFilters },
        () => this._showFilteredResources()
      );
    }

    if (activeToggle) {
      this.setState(
        () => ({ activeToggle: activeToggle }),
        () => this._showFilteredResources()
      );
    }
  }

  _showFilteredResources() {
    this.setState((prevState) => {
      const filteredResourcesPath = buildFilterPath(this.getPathBase, prevState.activeFilters);

      return ({
        doneLoading: prevState.filterSent || !prevState.doneLoading,
        filterSent: true,
        getPath: filteredResourcesPath,
        loadId: prevState.loadId + 1,
        pagesLoaded: 0,
        resources: []
      });
    });
  }

  _showToggles() {
    return !this.props.inDashboard;
  }

  _addResources(newResources) {
    const filteredResources = this._filterToggledAssignments(newResources);

    const state = {
      doneLoading: newResources.length < this.props.pageSize,
      pagesLoaded: this.state.pagesLoaded + 1,
      resources: filteredResources
    };

    if (this.state.filterSent) {
      state.doneLoading = false;
      state.filterSent = false;
    }

    this.props.setAssignments(filteredResources);

    this.setState(state);
  }

  _filterToggledAssignments(newResources) {
    const filteredResources = [...this.state.resources].concat(...formatAssignments(newResources));

    if (this.props.studentId && this.state.activeToggle !== 'none') {
      return filteredResources.filter(a => a.status === this.state.activeToggle);
    }
    if (this.props.studentId) {
      return filteredResources;
    }
    if (this.state.activeToggle !== 'none' && this.state.activeToggle !== 'all') {
      if (this.state.activeToggle === 'current') {
        return filteredResources.filter(filter => this.props.assignmentsHash['to_do'].map(c => c.id).includes(filter.id));
      }
      else {
        return filteredResources.filter(filter => this.props.assignmentsHash['past_due'].map(c => c.id).includes(filter.id));
      }
    }

    return filteredResources;
  }

  _resetResources() {
    this.setState({
      doneLoading: false,
      pagesLoaded: 0,
      resources: []
    });
  }

  toggleCollapse(e) {
    e.preventDefault();
    Cookie.save('isCollapsed', !this.state.isCollapsed);
    this.setState({ isCollapsed: !this.state.isCollapsed }, () => redirectLinks(this.cardContainer));
  }

  renderFilterTab() {
    return (
      <div
        ref={(node) => { this.cardContainer = node; }}
      >
        {this._renderAdvancedFiltersTitle()}
        {this._renderAdvancedFilters()}
      </div>
    );
  }

  _renderAdvancedFiltersTitle() {
    return (
      <button
        aria-expanded={!this.state.isCollapsed}
        className={styles.titleSection}
        onClick={this.toggleCollapse}
      >
        <div className={styles.title}> {I18n.t('advanced_filters')} </div>
        {this.renderArrow()}
      </button>
    );
  }

  renderArrow() {
    const arrowClass = this.state.isCollapsed ? 'fa-caret-down' : 'fa-caret-up';

    return (
      <div className={styles.arrow}>
        <i className={`fa ${arrowClass}`} aria-hidden="true" />
      </div>
    );
  }

  _renderAdvancedFilters() {
    if (this.state.isCollapsed) return (<div className={styles.borderBottom} />);

    return (
      <div className={styles.borderBottom}>
        <AdvancedFilters
          properties={[
            {
              isDate: true,
              label: 'Due date',
              operators: ['eq', 'not_eq', 'gteq', 'lteq'],
              value: 'due_datetime',
              withArgs: true
            },
            {
              autofillOptions: this.props.lessonAutofillOptions,
              disableSearch: true,
              label: 'Lesson',
              operators: ['eq', 'not_eq'],
              value: 'assignments_models_lesson_id'
            }
          ]}
          resources={this.state.resources}
          updateActiveFilters={this._updateActiveFilters}
          initialFilters={this.getInitialFilters()}
          filterLabel={null}
          showClearButton={false}
        />
      </div>
    );
  }

  _renderFilterToggles() {
    if (this.props.studentId) {
      return (
        <div className="mb20">
          <AssignmentFilterButton
            active={this.state.activeToggle === 'to_do'}
            buttonText={`${I18n.t('to_do')} (${this.props.assignmentCounts.toDo})`}
            handleToggle={this._updateActiveFilters}
            iconStyles={`fa fa-clock-o ${styles.fa} ${styles.clock}`}
            panelID="assignment-filter-to-do"
            value="to_do"
          />
          <AssignmentFilterButton
            active={this.state.activeToggle === 'past_due'}
            buttonText={`${I18n.t('past_due')} (${this.props.assignmentCounts.pastDue})`}
            handleToggle={this._updateActiveFilters}
            iconStyles={`fa fa-times-circle ${styles.fa} ${styles.timesCircle}`}
            panelID="assignment-filter-past-due"
            value="past_due"
          />
          <AssignmentFilterButton
            active={this.state.activeToggle === 'completed'}
            buttonText={`${I18n.t('completed')} (${this.props.assignmentCounts.completed})`}
            handleToggle={this._updateActiveFilters}
            iconStyles={`fa fa-check ${styles.fa} ${styles.check}`}
            panelID="assignment-filter-completed"
            value="completed"
          />
        </div>
      );
    }
    return (
      <div className="mb20">
        <AssignmentFilterButton
          active={this.state.activeToggle === 'all'}
          buttonText={`${I18n.t('all')} (${this.props.assignmentCounts.all})`}
          handleToggle={this._updateActiveFilters}
          panelID="assignment-filter-all"
          value="all"
        />
        <AssignmentFilterButton
          active={this.state.activeToggle === 'current'}
          buttonText={`${I18n.t('current')} (${this.props.assignmentCounts.toDo})`}
          handleToggle={this._updateActiveFilters}
          panelID="assignment-filter-current"
          value="current"
        />
        <AssignmentFilterButton
          active={this.state.activeToggle === 'past'}
          buttonText={`${I18n.t('past')} (${this.props.assignmentCounts.pastDue})`}
          handleToggle={this._updateActiveFilters}
          panelID="assignment-filter-past"
          value="past"
        />
      </div>
    );
  }

  _getNoRecordsMessage() {
    if (!this.props.studentId) return 'No data available in table';

    if (this.state.activeToggle === 'to_do') return 'You have no assignments to do.';
    if (this.state.activeToggle === 'past_due') return 'You have no assignments past due.';
    if (this.state.activeToggle === 'completed') return 'You have no assignments completed.';

    return 'No data available in table';
  }

  render() {
    return (
      <div className="tw-bg-white">
        <div className={styles.headerItems}>
          {!this.props.inDashboard && (
            <div className={styles['title-with-toggle-buttons']}>
              <h1 className="main_header mt15 mr20">
                {I18n.t('assignments')}
              </h1>
              {['Teacher', 'Coordinator', 'Admin'].includes(this.props.userType) &&
                <SettingsShortcut settingsType="assignments" />
              }
            </div>
          )}
        </div>

        {this._showToggles() && this._renderFilterToggles()}

        {!this.props.studentId && this.renderFilterTab()}

        {this.renderAdd()}

        {this.renderAssignmentModal()}

        <PaginatedTable
          buttons={[{
            className: 'btn btn--sm btn--outline-purple mr10',
            extend: 'toggleExpandAll'
          }]}
          getUrl={this.state.getPath}
          addResources={this._addResources}
          columns={this.props.columns}
          columnMapping={this.props.columns.map(c => (c.name === 'actions' ? null : c.name))}
          expandCallback={this.assignmentInfo}
          expandCallbackAfterInit={this.assignmentInfoAfterInit}
          defaultExpandRows={this.props.defaultExpandRows}
          columnDefs={this.columnDefs()}
          defaultOrder={this.defaultOrder()}
          handleResourcesManually
          hideButtons={this.props.hideButtons}
          paging={this.props.paging}
          pageLength={this.props.pageLength}
          showPagingOptions={this.props.showPagingOptions}
          info={this.props.info}
          resources={this.state.resources}
          doneLoading={this.state.doneLoading}
          resetResources={this._resetResources}
          pagesLoaded={this.state.pagesLoaded}
          noRecordsMessage={this._getNoRecordsMessage()}
        />
      </div>
    );
  }
}
