import React from 'react';
import I18n from 'i18n-js';
import * as Routes from '@/modules/routes';
import ActionBar from '@/components/shared/NewLessonOverview/Cards/ActionBar';
import ActionBarLink from '@/components/shared/NewLessonOverview/Cards/ActionBar/ActionBarButtons/ActionBarLink';
import Card from '@/components/shared/NewLessonOverview/Cards/Card';
import Copy from '@/components/shared/NewLessonOverview/Cards/ActionBar/ActionBarButtons/SlideShowButtons/Copy';
import Edit from '@/components/shared/NewLessonOverview/Cards/ActionBar/ActionBarButtons/SlideShowButtons/Edit';
import useLessonOverviewContext from '@/components/shared/NewLessonOverview/useLessonOverviewContext';
import { CardBody } from '@/components/shared/NewLessonOverview/Cards/Card/Card';
import { ICON_MAP } from '@/components/shared/NewLessonOverview/LessonOptions/utils';
import { dateTimeToUserTimeZone } from '@/utils/functions';
import { slideShowInitialValues } from '@/components/shared/NewLessonOverview/Cards/utils';
import deleteIcon from 'images/icons/delete-lined.svg';
import printIcon from 'images/icons/printK5.svg';

interface SlideShowCardProps {
  isTeacherSlideShow?: boolean;
  slideShow: any;
}

const SlideShowCard = ({
  isTeacherSlideShow,
  slideShow,
}: SlideShowCardProps) => {
  const lessonId = useLessonOverviewContext(state => state.lessonId);
  const isStaffer = useLessonOverviewContext(state => state.isStaffer);
  const programId = useLessonOverviewContext(state => state.programId);
  const ariaLabel = `${I18n.t('print')} ${slideShow?.title}`;
  const printRoute = Routes.pdf_shared_program_lesson_path(programId, lessonId, {
    locale: I18n.locale,
  });
  const time = isTeacherSlideShow ? undefined : (slideShow.lesson?.class_time || '-');
  const page = isTeacherSlideShow ? undefined : '-';
  const slideShowUpdateTime = dateTimeToUserTimeZone(slideShow.updatedAt, 'l');
  const slideShowUpdateLabel = I18n.t('last_updated_on', { time: slideShowUpdateTime });

  const topLabel = isTeacherSlideShow ? slideShowUpdateLabel : I18n.t(slideShow.activityType);
  const indicatorMessage = isTeacherSlideShow ? I18n.t('teacher_slideshow_tooltip') : undefined;
  const initialValues = slideShowInitialValues(slideShow);
  const thumbnail = (slideShow.thumbnailUrl !== '/missing.png') ? slideShow.thumbnailUrl : '/placeholder.png';

  return (
    <Card
      label={slideShow.activityType}
      showThumbnail={!isTeacherSlideShow}
      type={slideShow.activityType}
    >
      <CardBody
        ariaLabel={slideShow.title}
        iconSrc={ICON_MAP[slideShow.activityType]}
        linkHref={slideShow.links?.unfilteredPresentPath}
        linkLabel={slideShow.title}
        page={page}
        showThumbnail={!isTeacherSlideShow}
        thumbnail={thumbnail}
        time={time}
        tooltipIndicator={indicatorMessage}
        topLabel={topLabel}
      />
      {isStaffer && (
        <ActionBar
          href={slideShow.links?.unfilteredPresentPath}
          iconType="play"
          initialValues={initialValues}
        >
          <Copy slideShow={slideShow} />
          <Edit
            editExistingSlideShow={isTeacherSlideShow}
            slideShow={slideShow}
          />
          <ActionBarLink
            href={printRoute}
            icon={printIcon}
            label={ariaLabel}
            tag="print"
          />
          {isTeacherSlideShow && !!slideShow.links?.deletePath && (
            <ActionBarLink
              dataConfirm="Are you sure you want to delete this lesson guide?"
              dataMethod="delete"
              href={slideShow.links.deletePath}
              icon={deleteIcon}
              tag="delete"
              useNewTab={false}
            />
          )}
        </ActionBar>
      )}
    </Card>
  );
};

export default SlideShowCard;
