import React from 'react';
import I18n from 'i18n-js';
import { Lesson, SectionType } from '@/types/api/LessonOverview';
import { twMerge } from '@/utils';
import useLessonOverviewContext from '@/components/shared/NewLessonOverview/useLessonOverviewContext';
import LessonSubheader from '../LessonSubheader';
import AssessmentsCard from '../Cards/AssessmentsCard';
import LessonGameCard from '../Cards/LessonGameCard';
import NotebookCards from '../Cards/NotebookCards';

interface AssessWithProps {
  lesson: Lesson;
  sections: SectionType[];
  subheaderNumber?: number;
}

const AssessWith = ({
  lesson,
  sections,
  subheaderNumber,
}: AssessWithProps) => {
  const isStaffer = useLessonOverviewContext(state => state.isStaffer);
  const hasAssessments = useLessonOverviewContext(state => state.hasAssessments);

  return (
    <LessonSubheader
      subheaderNumber={subheaderNumber}
      title={I18n.t('assess_with')}
    >
      <div
        className={twMerge(
          'tw-grid tw-grid-cols-2 2xl:tw-grid-cols-[repeat(3,_minmax(25rem,_1fr))]',
          'tw-grid-template-cols-[25rem] tw-grid-rows-auto',
        )}
      >
        <NotebookCards
          lesson={lesson}
          sections={sections}
        />

        {lesson.lessonGameAssignables.length > 0 && (
          <LessonGameCard type="lesson-game" />
        )}

        {(hasAssessments || isStaffer) && (
          <AssessmentsCard type="assessments" />
        )}
      </div>
    </LessonSubheader>
  );
};

export default AssessWith;
