import { SLIDE_SHOW_MODEL_TYPES } from '@/components/shared/slide_shows/utils';

// todo: type
export const slideShowInitialValues = (slideShow) => {
  if (slideShow.assignable[0]?.slideGroups?.length) {
    return {
      modelType: SLIDE_SHOW_MODEL_TYPES[slideShow.activityType],
      modelsToAssign: slideShow.assignable[0].slideGroups,
      slideShowSelect: slideShow.assignable[0].value,
    };
  }

  return {
    modelType: SLIDE_SHOW_MODEL_TYPES[slideShow.activityType],
    modelsToAssign: slideShow.assignable[0].value,
  };
};
