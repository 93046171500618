import React from 'react';
import I18n from 'i18n-js';
import * as Routes from '@/modules/routes';
import useLessonOverviewContext from '@/components/shared/NewLessonOverview/useLessonOverviewContext';
import gameIcon from 'images/icons/game-icon.svg';
import Card from './Card';
import { CardBody } from './Card/Card';
import ActionBar from './ActionBar';

type LessonGameCardProps = {
  type: string,
};

const LessonGameCard = ({ type }: LessonGameCardProps) => {
  const lesson = useLessonOverviewContext(state => state.lesson);
  const programId = useLessonOverviewContext(state => state.programId);
  const isStaffer = useLessonOverviewContext(state => state.isStaffer);

  const { isAssigned } = lesson.challenge || {};

  return (
    <Card label="games" type={type}>
      <CardBody
        ariaLabel={I18n.t('lesson_games')}
        iconSrc={gameIcon}
        linkHref={Routes.library_shared_program_games_path(programId, { lesson_games: true, lesson_id: lesson.id })}
        linkLabel={I18n.t('lesson_games')}
        topLabel={I18n.t('games')}
      />
      {isStaffer && (
        <ActionBar
          href={Routes.library_shared_program_games_path(programId, { lesson_games: true, lesson_id: lesson.id })}
          iconType="arrow"
          initialValues={{
            modelType: 'games',
            modelsToAssign: [lesson.lessonGameAssignables[0].value],
          }}
          isAssigned={isAssigned}
          label={I18n.t('lesson_games')}
        />
      )}
    </Card>
  );
};

export default LessonGameCard;
