import React from 'react';
import I18n from 'i18n-js';
import lightBulbIcon from 'images/icons/light-bulb.svg';
import { getModelToAssign, getLink } from '@/components/shared/LessonOverview/Cards/NotebookCard';
import useLessonOverviewContext from '@/components/shared/NewLessonOverview/useLessonOverviewContext';
import { SectionType } from '@/types/api/LessonOverview';
import printIcon from 'images/icons/printK5.svg';
import ActionBarLink from './ActionBar/ActionBarButtons/ActionBarLink';
import Card, { CardBody } from './Card/Card';
import ActionBar from './ActionBar';

type NotebookCardProps = {
  fastTrack: boolean,
  notebookName: string,
  notebookPath: string,
  section: SectionType,
  type: string,
};

const NotebookCard = ({
  fastTrack, notebookName, notebookPath, section, type,
}: NotebookCardProps) => {
  const lessonId = useLessonOverviewContext(state => state.lessonId);
  const isStaffer = useLessonOverviewContext(state => state.isStaffer);
  const program = useLessonOverviewContext(state => state.program);
  const investigationSection = section.investigation;
  // Investigation Notebook tab of MS Sci classes don't have the print pdf option.
  // If notebook is from stream, if the notebook isn't fast track, and if the program is science.
  const hidePrintButton = (investigationSection && !fastTrack && program.science);

  const initialValues = {
    modelType: investigationSection ? 'investigation' : 'sectionNotebook',
    modelsToAssign: getModelToAssign(section, investigationSection, fastTrack, program),
  };

  return (
    <Card label="notebook" type={type}>
      <CardBody
        ariaLabel={notebookName}
        iconSrc={lightBulbIcon}
        linkHref={notebookPath}
        linkLabel={notebookName}
        topLabel={I18n.t(type)}
      />
      {isStaffer && (
        <ActionBar
          href={notebookPath}
          iconType="arrow"
          initialValues={initialValues}
          isAssigned={section.isAssigned}
          label={notebookName}
        >
          {!hidePrintButton && (
            <ActionBarLink
              href={getLink(investigationSection, fastTrack, program, lessonId)}
              icon={printIcon}
              label={I18n.t('print_notebook')}
              tag="print"
            />
          )}
        </ActionBar>
      )}
    </Card>
  );
};

export default NotebookCard;
