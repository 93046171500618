import React from 'react';
import ILesson from '@/types/api/Lessons';
import { IUnit } from '../types';
import useProgramContentStore from './useProgramContentStore';
import ContentCheckbox from './ContentCheckbox';
import ContentRadio from './ContentRadio';

interface UnitInputsProps {
  unit: IUnit & {
    lessons: ILesson[];
  };
}

const UnitInputs = ({ unit }: UnitInputsProps) => {
  const { id, title_with_position: title } = unit;
  const lessonIds = unit.lessons.map(l => l.id);
  const reuseIds = useProgramContentStore(state => state.reuseUnitIds);
  const copyIds = useProgramContentStore(state => state.copyUnitIds);
  const addId = useProgramContentStore(state => state.addUnitId);
  const removeId = useProgramContentStore(state => state.removeUnitId);
  const anyLessonsChecked = useProgramContentStore(state => state.anyLessonsChecked);
  const allLessonsChecked = useProgramContentStore(state => state.allLessonsChecked);
  const toggleAllLessons = useProgramContentStore(state => state.toggleAllLessons);
  const isChecked = allLessonsChecked(lessonIds);
  const isIndeterminate = reuseIds.includes(id) || copyIds.includes(id) || anyLessonsChecked(lessonIds);

  return (
    <div className="tw-flex">
      <ContentCheckbox
        checked={isChecked}
        isIndeterminate={isIndeterminate}
        label={title}
        onChange={() => toggleAllLessons(lessonIds)}
      />

      {(isChecked || isIndeterminate) && (
        <div className="tw-flex tw-flex-auto tw-justify-end">
          <div className="tw-flex tw-w-[250px]">
            <ContentRadio
              checked={copyIds.includes(id)}
              value="copy"
              onChange={() => {
                addId('copy', id);
                removeId('reuse', id);
              }}
            />

            <ContentRadio
              checked={!reuseIds.includes(id) && !copyIds.includes(id)}
              value="doNotUse"
              onChange={() => {
                removeId('copy', id);
                removeId('reuse', id);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UnitInputs;
