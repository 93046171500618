import React from 'react';
import I18n from 'i18n-js';
import Tooltip from '@/components/common/Tooltip';
import cn from '@/utils/cn';
import useLessonOverviewContext from '@/components/shared/NewLessonOverview/useLessonOverviewContext';
import AsyncAssignmentModal from '@/components/staffer/AssignmentModal/AsyncAssignmentModal';
import { useModalManager } from '@/components/common/Modal';
import { diveDeeperFormat } from '@/components/shared/NewLessonOverview/DiveDeeper/DiveDeeper.utils';
import ReadingCard from './ReadingCard';
import ReferenceMaterialCard from './ReferenceMaterialCard';

const DiveDeeper = () => {
  const lesson = useLessonOverviewContext(state => state.lesson);
  const sections = useLessonOverviewContext(state => state.sections);
  const programId = useLessonOverviewContext(state => state.programId);
  const stafferId = useLessonOverviewContext(state => state.stafferId);

  if (!lesson || !sections) return null;

  const modalManager = useModalManager();
  const { readings = [], referenceMaterials = [] } = diveDeeperFormat(lesson, sections);

  if (!readings && !referenceMaterials) return null;

  return (
    <div
      className={cn(
        'tw-h-full tw-min-w-[30%] tw-w-[45%] tw-bg-[#F6F5FA] tw-px-[2rem] tw-overflow-x-hidden tw-overflow-y-auto'
      )}
    >
      <div className="tw-flex tw-items-center tw-mt-[1.5rem] tw-mb-[2rem]">
        <h2 className="tw-text-2xl tw-mr-[0.75rem] tw-pb-0"> {I18n.t('dive_deeper_with')} </h2>
        <Tooltip hideOnClick content={I18n.t('lesson_subheader_dive_deeper')} distance="15" size="medium">
          <span>
            <i aria-hidden="true" className="tw-text-[1.5rem] fa fa-info-circle fa-lg" />
          </span>
        </Tooltip>
      </div>

      {readings?.map(reading => (
        <ReadingCard
          key={`reading-${reading.id}`}
          openAssign={modalManager.open}
          reading={reading}
        />
      ))}
      {referenceMaterials?.map(referenceMaterial => (
        <ReferenceMaterialCard
          key={`referenceMaterial-${referenceMaterial.id}`}
          referenceMaterial={referenceMaterial}
        />
      ))}
      {sections && (
      <AsyncAssignmentModal
        closeModal={modalManager.close}
        handlePostSave={modalManager.close}
        initialLessonId={lesson.id}
        initialValues={{
          modelType: 'sectionNotebook',
          modelsToAssign: sections.map(section => section.assignable.value),
        }}
        modalIsOpen={modalManager.isOpen}
        programId={programId}
        stafferId={stafferId}
      />
      )}
    </div>
  );
};

export default DiveDeeper;
