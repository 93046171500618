import React from 'react';
import I18n from 'i18n-js';
import LibraryCard from '@/components/shared/NewLessonOverview/Libraries/LibraryCard';
import useLessonOverviewContext from '@/components/shared/NewLessonOverview/useLessonOverviewContext';
import librariesUtils from './Libraries.utils';

const Libraries = () => {
  const lessonId = useLessonOverviewContext(state => state.lessonId);
  const unitId = useLessonOverviewContext(state => state.unitId);
  const civicsLibrary = useLessonOverviewContext(state => state.civicsLibrary);
  const primarySourceLibrary = useLessonOverviewContext(state => state.primarySourceLibrary);
  const programId = useLessonOverviewContext(state => state.programId);
  const libraries = librariesUtils({ civicsLibrary, lessonId, primarySourceLibrary, programId, unitId });

  return (
    <div className=" tw-h-full tw-w-full tw-bg-light-grey tw-px-[2rem]">
      <h2 className="tw-text-[1.5rem] tw-mb-[2rem] tw-mt-[1.5rem]">
        {I18n.t('looking_for_more')}
      </h2>
      <div className="tw-flex tw-flex-row tw-gap-x-[1rem]">
        {libraries.map(libraryCard => (
          <LibraryCard
            key={libraryCard.title_tag}
            icon={libraryCard.icon}
            path={libraryCard.path}
            titleTag={libraryCard.title_tag}
          />
        ))}
      </div>
    </div>
  );
};

export default Libraries;
