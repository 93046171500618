import { useContext } from 'react';
import { useStore } from 'zustand';
import { LessonOverviewContext } from './NewLessonOverviewStoreProvider';
import { NewLessonOverviewProps } from './types';

type LessonOverviewContextStore = NewLessonOverviewProps & {
  isStaffer: boolean;
};

const useLessonOverviewContext = <T>(
  selector: (state: LessonOverviewContextStore) => T,
  equalityFn?: (left: T, right: T) => boolean,
) => {
  const store = useContext(LessonOverviewContext);

  return useStore(store, selector, equalityFn);
};

export default useLessonOverviewContext;
