import React from 'react';
import Modal, { useModalManager } from '@/components/common/Modal';
import Form from '@/components/shared/LessonOverview/Cards/ActionBar/Form';
import useLessonOverviewContext from '@/components/shared/NewLessonOverview/useLessonOverviewContext';
import ISlideShow from '@/types/api/SlideShow';
import copyIcon from 'images/icons/copySlide.svg';
import I18n from 'i18n-js';
import ActionBarButton from '../ActionBarButton/ActionBarButton';

type CopyButtonType = {
  slideShow: ISlideShow;
  fromSlideShow?: boolean
};

const Copy = ({ slideShow, fromSlideShow } : CopyButtonType) => {
  const lessonId = useLessonOverviewContext(state => state.lessonId);
  const programId = useLessonOverviewContext(state => state.programId);
  const modalManager = useModalManager();

  return (
    <>
      <ActionBarButton
        icon={copyIcon}
        tag="copy"
        onClick={modalManager.open}
      />
      <Modal
        closeModal={modalManager.close}
        headerText={I18n.t('save_new_presentation')}
        isOpen={modalManager.isOpen}
      >
        <Form
          button="clone"
          fromSlideShow={fromSlideShow}
          id={slideShow.id}
          lessonId={lessonId}
          programId={programId}
          slideShowId={slideShow.slideShowId}
          title={slideShow.title}
          type={slideShow.className}
          useNewBuilder={slideShow.useNewBuilder}
        />
      </Modal>
    </>
  );
};

export default Copy;
