import React from 'react';
import I18n from 'i18n-js';
import Modal, { useModalManager } from '@/components/common/Modal';
import Form from '@/components/shared/LessonOverview/Cards/ActionBar/Form';
import ISlideShow from '@/types/api/SlideShow';
import ActionBarLink from '@/components/shared/NewLessonOverview/Cards/ActionBar/ActionBarButtons/ActionBarLink';
import useLessonOverviewContext from '@/components/shared/NewLessonOverview/useLessonOverviewContext';
import editIcon from 'images/icons/editSlide.svg';
import ActionBarButton from '../ActionBarButton/ActionBarButton';

type EditButtonProps = {
  slideShow: ISlideShow;
  fromSlideShow?: boolean;
  editExistingSlideShow?: boolean;
};

const Edit = ({ slideShow, fromSlideShow, editExistingSlideShow }: EditButtonProps) => {
  const programId = useLessonOverviewContext(state => state.programId);
  const lessonId = useLessonOverviewContext(state => state.lessonId);
  const modalManager = useModalManager();

  if (editExistingSlideShow) {
    return (
      <ActionBarLink
        href={`${slideShow.links.editPath}${slideShow.useNewBuilder ? '?new_builder=true' : ''}`}
        icon={editIcon}
        tag="edit"
        useNewTab={false}
      />
    );
  }

  return (
    <>
      <ActionBarButton
        icon={editIcon}
        tag="edit"
        onClick={modalManager.open}
      />
      <Modal
        closeModal={modalManager.close}
        headerText={I18n.t('save_new_presentation')}
        isOpen={modalManager.isOpen}
      >
        <Form
          button="edit"
          fromSlideShow={fromSlideShow}
          id={slideShow.id}
          lessonId={lessonId}
          programId={programId}
          slideShowId={slideShow.slideShowId}
          title={slideShow.title}
          type={slideShow.className}
          useNewBuilder={slideShow.useNewBuilder}
        />
      </Modal>
    </>
  );
};

export default Edit;
