import anchorIcon from './anchoring-phenomenon.svg';
import assessmentsIcon from './assessments@2x.svg';
import challengeIcon from './challenge-icon.svg';
import checkIcon from './check.svg';
import discussIcon from './discuss.svg';
import explainAndProcessIcon from './explain-and-processing.svg';
import gamesIcon from './games.svg';
import geographyChallengeIcon from './geography-challenge.svg';
import hoiIcon from './HOI.svg';
import localConnectionsIcon from './local-connections.svg';
import observeIcon from './observe.svg';
import overviewIcon from './overview.svg';
import readingIcon from './reading.svg';
import readingCheckIcon from './reading-check.svg';
import settingsIcon from './settings.svg';
import timelineChallengeIcon from './timeline-challenge.svg';
import videoActivityIcon from './video-activity.svg';
import vocabIcon from './vocab.svg';
import ssWritingIcon from './writing-in-ss.svg';

const icons = {
  readingIcon,
  vocabIcon,
  hoiIcon,
  observeIcon,
  explainAndProcessIcon,
  videoActivityIcon,
  gamesIcon,
  assessmentsIcon,
  settingsIcon,
  checkIcon,
  anchorIcon,
  readingCheckIcon,
  discussIcon,
  localConnectionsIcon,
  overviewIcon,
  timelineChallengeIcon,
  geographyChallengeIcon,
  challengeIcon,
  ssWritingIcon,
};

export default icons;
