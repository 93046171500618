import I18n from 'i18n-js';
import React, { useState } from 'react';
import SlideShowCard from '@/components/shared/NewLessonOverview/Cards/SlideShowCard';
import { SectionType, SlideGroup, StafferSlideShows } from '@/types/api/LessonOverview';
import SlideGroupCard from '@/components/shared/NewLessonOverview/Cards/SlideGroupCard';
import { twMerge } from '@/utils';
import ReadingCard from '../Cards/ReadingCard';
import useLessonOverviewContext from '../useLessonOverviewContext';
import ShowSlideshowCheckbox from '../ShowSlideshowCheckbox';
import LessonSubheader from '../LessonSubheader';
import { shouldRenderSlideShow } from './utils';

type LessonOptionsProps = {
  slideGroups: SlideGroup[];
  slideShows: StafferSlideShows;
  sections: SectionType[];
  subheaderNumber?: number;
};

const LessonOptions = ({
  sections,
  slideGroups,
  slideShows,
  subheaderNumber,
}: LessonOptionsProps) => {
  if (!slideShows) return null;

  const isStaffer = useLessonOverviewContext(state => state.isStaffer);

  const firstTCISlideShow = slideShows.tci?.first;
  const newTCISlideShows = slideShows.tci?.newSlideShows ?? [];
  const oldTCISlideShows = slideShows.tci?.oldSlideShows ?? [];
  const teacherSlideShows = slideShows.staffer ?? [];
  const slideShowsToRender = [...newTCISlideShows, ...oldTCISlideShows];

  const [showTeacherSlideShows, setShowTeacherSlideShows] = useState(false);

  return (
    <LessonSubheader subheaderNumber={subheaderNumber} title={I18n.t('choose_a_lesson')}>
      {isStaffer && (
        <ShowSlideshowCheckbox isChecked={showTeacherSlideShows} setIsChecked={setShowTeacherSlideShows} />
      )}
      <div
        className={twMerge(
          'tw-grid tw-grid-cols-[repeat(3,_minmax(25rem,_1fr))]',
          'tw-grid-template-cols-[25rem] tw-grid-rows-auto tw-grid-flow-col',
        )}
      >
        {firstTCISlideShow && (
          <div className="tw-row-span-2">
            <SlideShowCard slideShow={firstTCISlideShow} />
          </div>
        )}

        {slideShowsToRender?.map((slideShow) => {
          if (!shouldRenderSlideShow(slideShow)) return null;

          return (
            <div className="tw-row-span-2">
              <SlideShowCard slideShow={slideShow} />
            </div>
          );
        })}
        <ReadingCard
          sections={sections.filter(s => s.kind === 'default' && s.investigation === false)}
        />
        {slideGroups?.map(slideGroup => (
          <SlideGroupCard slideGroup={slideGroup} />
        ))}
      </div>

      <div className="tw-h-full tw-w-full tw-flex tw-flex-row tw-flex-wrap">
        {isStaffer && showTeacherSlideShows && teacherSlideShows?.map((slideShow) => {
          if (!shouldRenderSlideShow(slideShow)) return null;

          return (
            <SlideShowCard
              isTeacherSlideShow
              slideShow={slideShow}
            />
          );
        })}
      </div>
    </LessonSubheader>
  );
};

export default LessonOptions;
