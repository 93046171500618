import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../AssignmentsTable.module.scss';

const AssignmentFilterButton = ({
  active, buttonText, handleToggle, iconStyles, value, panelID
}) => {
  const toggleState = value;
  const [disabled, setDisabled] = useState(false);
  const buttonID = `assignment-filter-button-${value}`;

  const onToggle = () => {
    if (active) return;
    handleToggle(null, toggleState);
    setDisabled(true);
    setTimeout(() => setDisabled(false), 800);
  };

  return (
    <div className={`${styles.toggleWrapper}`}>
      <button
        id={buttonID}
        type="button"
        className={`btn btn--card ${active ? styles.active : ''} ${styles.studentAssignmentToggle}`}
        onClick={onToggle}
        disabled={disabled}
        aria-selected={active}
        aria-controls={panelID}
        role="tab"
      >
        <i className={iconStyles} />&nbsp;
        {buttonText}
      </button>
      {active && (
        <div
          id={panelID}
          role="tabpanel"
          aria-labelledby={buttonID}
          className={styles.tabPanel}
        >
          {/* Content of the tab panel */}
        </div>
      )}
    </div>
  );
};

AssignmentFilterButton.propTypes = {
  active: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  handleToggle: PropTypes.func.isRequired,
  iconStyles: PropTypes.string,
  panelID: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,

};

export default AssignmentFilterButton;
