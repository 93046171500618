import create from 'zustand';
import ILesson from '@/types/api/Lessons';

type NewContentAction = 'copy' | 'reuse';

interface IProgramContentVariables {
  reuseLessonIds: number[];
  copyLessonIds: number[];
  reuseUnitIds: number[];
  copyUnitIds: number[];
  addLessonId: (type: NewContentAction, id: number) => void;
  addUnitId: (type: NewContentAction, id: number) => void;
  removeLessonId: (type: NewContentAction, id: number) => void;
  removeUnitId: (type: NewContentAction, id: number) => void;
  anyLessonsChecked: (lessonIds: number[]) => boolean;
  allLessonsChecked: (lessonIds: number[]) => boolean;
  toggleAllLessons: (lessonIds: number[]) => void;
  toggleLessonOff: (lessonId: number) => void;
  toggleLessonOn: (lessonId: number) => void;
  isAnyChecked: () => boolean;
  reset: () => void;
  parentLessons: ILesson[];
  setParentLessons: (lessons: ILesson[]) => void;
}

const useProgramContentStore = create<IProgramContentVariables>((set, get) => ({
  reuseLessonIds: [],
  copyLessonIds: [],
  copyUnitIds: [],
  reuseUnitIds: [],
  parentLessons: [],
  addLessonId: (type, id) => {
    const lessonIds = new Set(get()[`${type}LessonIds`]);
    lessonIds.add(id);
    set({ [`${type}LessonIds`]: [...lessonIds] });
  },
  addUnitId: (type, id) => {
    const unitIds = new Set(get()[`${type}UnitIds`]);
    unitIds.add(id);
    set({ [`${type}UnitIds`]: [...unitIds] });
  },
  removeLessonId: (type, id) => {
    const lessonIds = new Set(get()[`${type}LessonIds`]);
    lessonIds.delete(id);
    set({ [`${type}LessonIds`]: [...lessonIds] });
  },
  removeUnitId: (type, id) => {
    const unitIds = new Set(get()[`${type}UnitIds`]);
    unitIds.delete(id);
    set({ [`${type}UnitIds`]: [...unitIds] });
  },
  isAnyChecked: () => {
    const { reuseUnitIds, reuseLessonIds, copyLessonIds, copyUnitIds } = get();
    if (reuseUnitIds.length > 0) return true;
    if (reuseLessonIds.length > 0) return true;
    if (copyLessonIds.length > 0) return true;
    return copyUnitIds.length > 0;
  },
  anyLessonsChecked: (lessonIds: number[]) => {
    const { reuseLessonIds, copyLessonIds } = get();

    if (reuseLessonIds.some(lessonId => lessonIds.includes(lessonId))) return true;

    return copyLessonIds.some(lessonId => lessonIds.includes(lessonId));
  },
  allLessonsChecked: (lessonIds: number[]) => {
    const { reuseLessonIds, copyLessonIds } = get();

    return lessonIds.every(id => [...reuseLessonIds, ...copyLessonIds].includes(id));
  },
  reset: () => set({
    reuseLessonIds: [],
    reuseUnitIds: [],
    copyLessonIds: [],
    copyUnitIds: [],
  }),
  toggleAllLessons: (lessonIds) => {
    const { allLessonsChecked, toggleLessonOff, toggleLessonOn } = get();

    if (allLessonsChecked(lessonIds)) {
      lessonIds.forEach(lessonId => toggleLessonOff(lessonId));
    } else {
      lessonIds.forEach(lessonId => toggleLessonOn(lessonId));
    }
  },
  toggleLessonOff: (lessonId) => {
    const { removeLessonId } = get();

    removeLessonId('copy', lessonId);
    removeLessonId('reuse', lessonId);
  },
  toggleLessonOn: (lessonId) => {
    const { addLessonId } = get();

    addLessonId('reuse', lessonId);
  },
  setParentLessons: (lessons: ILesson[]) => {
    set({ parentLessons: lessons });
  },
}));

export default useProgramContentStore;
