import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { plato_api_for_assignments_question_answers_path } from '@/modules/routes';
import useGradesTableStore from '../../../store/useGradesTableStore';
import useSectionAssignments from '../useSectionAssignments';

interface Data {
  id: number;
  question_id: number;
  student_id: number;
}

const useAssignmentQuestionAnswers = () => {
  const classroom = useGradesTableStore(state => state.classroom);

  const { data: assignments } = useSectionAssignments();
  const isEnabled = assignments.length > 0;

  const route = plato_api_for_assignments_question_answers_path({
    assignment_ids: assignments.map(a => a.id),
    classroom_id: classroom.id,
    only: ['id', 'question_id', 'student_id'],
  });

  return useQuery({
    placeholderData: [],
    queryFn: () => (
      axios.get<{ data: Data[] }>(route).then((res) => res.data.data)
    ),
    queryKey: ['useAssignmentQuestionAnswers', assignments.map(a => a.id), classroom.id],
    enabled: isEnabled,
  });
};

export default useAssignmentQuestionAnswers;
