import React, { useState } from 'react';
import Modal, { Footer } from '@/components/common/Modal';
import { GET_SLIDE_OBJECT } from '@/components/admin/SlideShow/Utils/slideShowGraphQL';
import { useQuery } from '@apollo/client';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { plato_api_set_section_slide_object_path } from '@/modules/routes';
import Axios from 'axios';
import SectionsSelect from
  '../SlideObjectText/CustomFroalaCommands/ElementInsert/ModalInputs/Components/SectionsSelect';


interface ReadingSelectModalProps {
  modalManager: ModalManager;
  slideObjectId: string;
}

const ReadingSelectModal = ({ modalManager, slideObjectId }: ReadingSelectModalProps) => {
  const [selectedSection, setSelectedSection] = useState(null);

  const programId = useSlideShowContext(state => state.programId);
  const updateSlideObject = useSlideShowContext(state => state.updateSlideObject);

  const { refetch: getSlideObject } = useQuery(GET_SLIDE_OBJECT, {
    fetchPolicy: 'network-only',
    skip: true,
  });

  const refetchSlideObject = async () => {
    const { data } = await getSlideObject({ id: slideObjectId });
    updateSlideObject(data.slideObject);
  };

  const handleSubmit = () => {
    Axios.post(plato_api_set_section_slide_object_path(slideObjectId), {
      program_id: programId,
      section_id: selectedSection,
    }).then(() => {
      refetchSlideObject();
    }).then(() => {
      modalManager.close();
    });
  };

  return (
    <Modal
      headerText="Add Reading"
      {...modalManager}
    >
      <SectionsSelect
        multiSelect={false}
        sectionIds={selectedSection}
        sectionType="Lesson"
        setSectionIds={setSelectedSection}
      />
      <Footer
        primaryButtonCallback={handleSubmit}
        primaryButtonDisabled={!selectedSection}
        primaryButtonText="Submit"
        primaryButtonSubmit={false}
        secondaryButtonCallback={() => modalManager.close()}
      />
    </Modal>
  );
};

export default ReadingSelectModal;
