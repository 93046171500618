import I18n from 'i18n-js';
import React from 'react';
import { clsx } from 'clsx';
import useLessonOverviewContext from '@/components/shared/NewLessonOverview/useLessonOverviewContext';

interface CardTitleProps {
  halfCard?: boolean;
  linkHref: string;
  linkLabel: string;
  page?: string;
  showThumbnail?: boolean;
  target: '_blank' | '';
  time?: string;
  topLabel?: string;
}

export const CardTitle = ({
  halfCard,
  linkHref,
  linkLabel,
  page,
  showThumbnail = false,
  target,
  time,
  topLabel,
}: CardTitleProps) => {
  const unitColor = useLessonOverviewContext(state => state.unitColor);

  return (
    <div className="tw-h-20 tw-pl-4">
      {topLabel && (
        <span className="tw-text-very-dark-grey tw-text-lg tw-leading-6">
          {topLabel}
        </span>
      )}
      <div className="tw-text-tci-purple tw-text-xl tw-font-bold tw-leading-6 tw-mb-4 tw-tracking-wide">
        <a
          aria-label={linkLabel}
          className="hover:tw-text-very-dark-grey"
          href={linkHref}
          target={target}
        >
          {linkLabel}
        </a>
      </div>
      <div
        className={clsx(
          'tw-flex tw-absolute tw-bottom-0 tw-mb-4 tw-text-sm', {
            'tw-mb-2': showThumbnail,
            'tw-mt-10': halfCard && !topLabel,
          },
        )}
      >
        {page && (
          <p className="tw-mr-8">
            <span
              className="tw-mr-1 tw-font-bold"
              style={{ color: `#${unitColor}` }}
            >
              {I18n.t('page')}
            </span>
            <span className="tw-text-very-dark-grey">
              {page || '-'}
            </span>
          </p>
        )}
        {time && (
          <p>
            <span
              className="tw-mr-1 tw-font-bold"
              style={{ color: `#${unitColor}` }}
            >
              {I18n.t('time')}
            </span>
            <span className="tw-text-very-dark-grey">
              {time || '-'}
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default CardTitle;
