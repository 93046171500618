import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import * as Routes from 'modules/routes';
import { SelectField } from '@/components/common/Forms';
import useAssignablesStore from './store/useAssignablesStore';
import sectionStyles from '../Section.module.scss';
import styles from './WhatSection.module.scss';
import { useQuery } from '@tanstack/react-query';

interface AssignableTypeDropdownProps {
  programId: number;
}

const AssignableTypeDropdown = ({ programId }: AssignableTypeDropdownProps) => {
  const selectedUnitLesson = useAssignablesStore(state => state.selectedUnitLesson);
  const setSelectedType = useAssignablesStore(state => state.setSelectedType);
  const setStore = useAssignablesStore(state => state.setStore);
  const initialType = useAssignablesStore(state => state.initialValues.modelType);
  const [assignableTypes, setAssignableTypes] = useState([]);
  const formChangeValue = useAssignablesStore(state => state.formChangeValue);
  const loadingLessons = useAssignablesStore(state => state.loadingLessons);

  const fetchAssignableTypes = async (modelId, modelType) => {
    const response = await Axios.get(
      Routes.plato_api_get_assignable_types_assignables_path({
        model_id: modelId,
        model_type: modelType,
        program_id: programId,
      }),
    );
    return response.data.data;
  };

  const { isFetching: isLoadingTypes, refetch: refetchTypes } = useQuery(['fetchAssignableTypes'], () => {
    let modelType: string;
    let modelId: number | string;
    if (selectedUnitLesson?.value) {
      modelId = selectedUnitLesson.value;
      modelType = selectedUnitLesson.isUnit ? 'Unit' : 'Lesson';
    } else {
      modelType = 'Program';
      modelId = programId;
    }

    return fetchAssignableTypes(modelId, modelType);
  }, { onSuccess: data => setAssignableTypes(data), onError: err => console.error(err) });

  useEffect(() => {
    setStore({ loadingTypes: isLoadingTypes });
  }, [isLoadingTypes]);

  useEffect(() => {
    refetchTypes();
  }, [selectedUnitLesson]);

  useEffect(() => {
    setStore({ score: 0 });
    if (isLoadingTypes || loadingLessons) return;

    setSelectedType(initialType);
    formChangeValue(initialType, 'type');
  }, [initialType, isLoadingTypes, loadingLessons]);

  const handleChange = (newType) => {
    setStore({ score: 0 });
    setSelectedType(newType);

    // change the value in FinalForm + clear modelsToAssign
    formChangeValue(newType, 'type');
    formChangeValue([], 'modelsToAssign');
    formChangeValue('', 'slideShowSelect');
  };

  return (
    <>
      <SelectField
        fieldClass={`${styles.largeField} ${styles.assignableSelector}`}
        label="Type"
        labelClass={sectionStyles.label}
        name="type"
        searchable={false}
        placeholder="Select one"
        onChange={handleChange}
        options={assignableTypes}
        required
        rowClass={`mb10 ${sectionStyles.fieldContainer}`}
      />
    </>
  );
};

export default AssignableTypeDropdown;
