import React, { createContext, useRef } from 'react';
import createLessonOverviewStore from '@/components/shared/NewLessonOverview/createLessonOverviewStore';
import { NewLessonOverviewStoreProviderProps } from './types';

type LessonOverviewStore = ReturnType<typeof createLessonOverviewStore>;

export const LessonOverviewContext = createContext<LessonOverviewStore | null>(null);

const NewLessonOverviewStoreProvider = ({ children, props }: NewLessonOverviewStoreProviderProps) => {
  const storeRef = useRef<LessonOverviewStore>();

  if (!storeRef.current) {
    storeRef.current = createLessonOverviewStore(props, storeRef);
  }

  return (
    <LessonOverviewContext.Provider value={storeRef.current}>
      {children}
    </LessonOverviewContext.Provider>
  );
};

export default NewLessonOverviewStoreProvider;
