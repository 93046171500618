import React, { useEffect } from 'react';
import Axios from 'axios';
import { plato_api_settings_path } from '@/modules/routes';
import Select from '@/components/common/ReactSelect5';
import Tooltip from '@/components/common/Tooltip';
import { GradeByType } from '@/components/staffer/NotebookGradeByMenu/types';
import sectionStyles from './Section.module.scss';
import useAssignablesStore from './WhatSection/store/useAssignablesStore';

const GRADE_BY_OPTIONS = [
  { label: 'Assignment', value: GradeByType.Assignment },
  { label: 'Lesson', value: GradeByType.Lesson },
  { label: 'Section', value: GradeByType.Section },
] as const;

type Option = typeof GRADE_BY_OPTIONS[number];

const GradeBySection = () => {
  const form = useAssignablesStore(state => state.form);
  const initialValues = useAssignablesStore(state => state.initialValues);
  const formChangeValue = useAssignablesStore(state => state.formChangeValue);
  const selectedType = useAssignablesStore(state => state.selectedType);
  const values = form.getState().values || {};
  const gradeByAssignmentValue = values.grading_notebook_by;
  const tooltipText = "Choose how to grade this lesson's reading assignments: by section, lesson, or assignment.";

  const getDefaultGradeBySetting = async () => {
    const defaultGradeBy = await Axios.get(plato_api_settings_path(), { params: { id: 'grade_notebooks_by' } })
      .then(response => response.data.grade_notebooks_by)
      .catch(() => console.log('error fetching grade by settings'));

    return defaultGradeBy || GradeByType.Section;
  };

  useEffect(() => {
    if (!initialValues || !selectedType) return;

    if (initialValues.gradingNotebookBy == null) {
      getDefaultGradeBySetting().then(setting => formChangeValue(setting, 'grading_notebook_by'));
    } else {
      formChangeValue(initialValues.gradingNotebookBy, 'grading_notebook_by');
    }
  }, [initialValues, selectedType]);

  return (
    <div className={sectionStyles.row}>
      <div className="bold">Assignment Settings:</div>
      <div className="tw-flex tw-items-center tw-pt-1">
        <label className="tw-pr-1" htmlFor="grade_by_field">
          Grade by
        </label>
        <div className="tw-pr-1">
          <Select
            className="tw-p-0 tw-w-[130px]"
            fontSizeOverride="13px"
            id="grade_by_field"
            menuHeightOverride="28px"
            options={GRADE_BY_OPTIONS}
            value={GRADE_BY_OPTIONS.find((opt: Option) => opt.value === gradeByAssignmentValue)}
            onChange={(opt: Option) => form.mutators.handleSelect(opt.value, 'grading_notebook_by')}
          />
        </div>
        <Tooltip
          content={tooltipText}
          size="medium"
          theme="white"
        >
          <span>
            <i aria-hidden className="fa fa-question-circle" />
            <span className="sr-only">
              {tooltipText}
            </span>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default GradeBySection;
