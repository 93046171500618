import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import * as Routes from '@/modules/routes';
import IAssignment from '@/components/interfaces/Assignment';
import useGradesTableStore from '../../store/useGradesTableStore';
import useClassroomLesson from './useClassroomLesson';
import { gradingByAssignment } from '../../Utils';

type ExtraAttributes = Required<Pick<IAssignment, 'question_ids' | 'reading_assignment_label'>>;
type Attributes = Omit<IAssignment, 'question_ids' | 'reading_assignment_label'>;
export type IAssignmentWithExtras = Attributes & ExtraAttributes;

interface IApiResponse {
  data: IAssignmentWithExtras[];
}

const useSectionAssignments = () => {
  const lesson = useGradesTableStore(state => state.lesson);
  const classroom = useGradesTableStore(state => state.classroom);

  const { data: classroomLesson } = useClassroomLesson(classroom.id, lesson?.id);
  const isEnabled = !!(lesson?.id && classroom?.id && gradingByAssignment(classroomLesson));

  const route = Routes.plato_api_assignments_path({
    for_classroom: classroom.id,
    for_section_assignables_by_lesson: lesson?.id,
    extra_attributes: ['reading_assignment_label', 'question_ids'],
  });

  return useQuery(['useSectionAssignments', lesson?.id], () => (
    axios
      .get<IApiResponse>(route)
      .then(res => res.data.data)
      .catch(() => ([] as IAssignmentWithExtras[]))
  ), { enabled: isEnabled, placeholderData: [] });
};

export default useSectionAssignments;
