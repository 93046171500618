import React from 'react';
import ILesson from '@/types/api/Lessons';
import useProgramContentStore from './useProgramContentStore';
import ContentRadio from './ContentRadio';
import ContentCheckbox from './ContentCheckbox';

interface LessonInputsProps {
  lesson: ILesson;
}

const LessonInputs = ({ lesson }: LessonInputsProps) => {
  const { id, title_with_position: title } = lesson;
  const reuseIds = useProgramContentStore(state => state.reuseLessonIds);
  const copyIds = useProgramContentStore(state => state.copyLessonIds);
  const addId = useProgramContentStore(state => state.addLessonId);
  const removeId = useProgramContentStore(state => state.removeLessonId);
  const isChecked = reuseIds.includes(id) || copyIds.includes(id);
  const parentLessons = useProgramContentStore(state => state.parentLessons);

  return (
    <div className="tw-flex">
      <ContentCheckbox
        checked={isChecked}
        disabled={parentLessons.map(l => l.id).includes(id)}
        label={title}
        onChange={() => {
          if (isChecked) {
            removeId('copy', id);
            removeId('reuse', id);
          } else {
            addId('reuse', id);
          }
        }}
      />

      {isChecked && (
        <div className="tw-flex tw-flex-auto tw-justify-end">
          <div className="tw-flex tw-w-[250px]">
            <ContentRadio
              checked={reuseIds.includes(id)}
              value="reuse"
              onChange={() => {
                addId('reuse', id);
                removeId('copy', id);
              }}
            />

            <ContentRadio
              checked={copyIds.includes(id)}
              value="copy"
              onChange={() => {
                addId('copy', id);
                removeId('reuse', id);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LessonInputs;
