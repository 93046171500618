import { createStore } from 'zustand';
import { NewLessonOverviewProps } from './types';

const createLessonOverviewStore = (
  props: NewLessonOverviewProps,
  storeRef: ReturnType<typeof createLessonOverviewStore>,
) => createStore<NewLessonOverviewProps>()((set, get) => ({
  civicsLibrary: props.civicsLibrary ?? false,
  hasAssessments: props.hasAssessments,
  isLoading: props.isLoading,
  isStaffer: !!props.stafferId,
  lesson: props.lesson,
  lessonId: props.lessonId,
  locale: props.locale,
  primarySourceLibrary: props.primarySourceLibrary ?? false,
  program: props.program,
  programId: props.programId,
  sections: props.sections,
  slideShows: props.slideShows,
  stafferId: props.stafferId,
  storeRef,
  unitColor: props.unitColor,
  unitId: props.unitId,
}));

export default createLessonOverviewStore;
