import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ConditionalWrap } from 'common/Utils';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import Section from '../Section';
import { assignmentInitialValuesPropType } from '../PropTypes';
import sectionStyles from '../Section.module.scss';
import UnitLessonSelector from './UnitLessonSelector';
import AssignableTypeDropdown from './AssignableTypeDropdown';
import AssignableModelsSelector from './AssignableModelsSelector';
import { PopulateStore } from './AssignableModels/utils';

export default class WhatSection extends Component {
  static propTypes = {
    form: PropTypes.shape({
      getState: PropTypes.func.isRequired
    }).isRequired,
    formChangeValue: PropTypes.func.isRequired,
    initialLessonId: PropTypes.number,
    initialValues: assignmentInitialValuesPropType,
    openAssessmentInNewTab: PropTypes.bool,
    programID: PropTypes.number.isRequired,
    score: PropTypes.number.isRequired,
    showWhatTitle: PropTypes.bool,
    stafferId: PropTypes.number
  };

  static defaultProps = {
    initialLessonId: null,
    initialValues: {},
    openAssessmentInNewTab: false,
    showWhatTitle: true,
  };

  static getDefaultValue(type, options) {
    if (type === 'lessonGame') return options[0].value;

    return '';
  }

  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  getSectionNotebookLabel() {
    return 'All';
  }

  renderError() {
    if (this.state.error) {
      return <div className={sectionStyles.fieldError}>{this.state.error}</div>;
    }

    return null;
  }

  renderSelectors() {
    return (
      <>
        <QueryClientProvider client={new QueryClient()}>
          <div className={sectionStyles.row}>
            <div className={`mb10 ${sectionStyles.fieldContainer}`}>

              <PopulateStore
                form={this.props.form}
                formChangeValue={this.props.formChangeValue}
                programId={this.props.programID}
                stafferId={this.props.stafferId}
                initialValues={{
                  gradingNotebookBy: this.props.initialValues.grading_notebook_by,
                  modelType: this.props.initialValues.modelType,
                  modelsToAssign: this.props.initialValues.modelsToAssign,
                  openAssessmentInNewTab: this.props.openAssessmentInNewTab,
                  randomizeAnswers: this.props.initialValues.randomize_answers,
                  readingAssignableSelections: this.props.initialValues.readingAssignableSelections,
                  slideShowSelect: this.props.initialValues.slideShowSelect,
                }}
              />

              <UnitLessonSelector
                initialUnitLessonId={this.props.initialLessonId}
                labelStyle={sectionStyles.label}
                programId={this.props.programID}
                formChangeValue={this.props.formChangeValue}
              />
            </div>
          </div>

          <div className={sectionStyles.row}>
            <AssignableTypeDropdown programId={this.props.programID} />
          </div>
          <div className={sectionStyles.row}>
            <AssignableModelsSelector
              form={this.props.form}
              formChangeValue={this.props.formChangeValue}
            />
          </div>
          <div className={sectionStyles.row} >

            <div className={`mb10 ${sectionStyles.fieldContainer}`}>
              <span className={sectionStyles.label}>Score</span>
              <span className={sectionStyles.scoreField}>{this.props.score}</span>
            </div>
          </div>
        </QueryClientProvider>
      </>
    );
  }

  render() {
    return (
      <ConditionalWrap
        condition={this.props.showWhatTitle}
        wrap={children => (
          <Section title="What">{children}</Section>
        )}
      >
        {this.renderSelectors()}
        {this.renderError()}
      </ConditionalWrap>
    );
  }
}
