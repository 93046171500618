import React, { useEffect, useRef, useState } from 'react';
import cn from '@/utils/cn';
import Axios from 'axios';
import { plato_api_copy_status_program_path } from '@/modules/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import pluralize from 'pluralize';

const POLL_INTERVAL = 5000;
const MAX_TIMEOUT = 600000; // 10 minutes

interface CopyRowProps {
  item: { title: string };
}

const CopyRow = ({ item }: CopyRowProps) => (
  <div className={`tw-bg-white tw-py-3 tw-px-5 tw-flex tw-justify-between tw-align-center
                    tw-border-b-[.5px] tw-border-solid tw-border-grey`}
  >
    <div className="tw-text-sm tw-font-bold">
      {item.title}
    </div>
    <div className="tw-flex tw-items-center tw-text-xl tw-pl-3">
      <FontAwesomeIcon
        className="tw-text-tci-purple fa-spin"
        icon={faCircleNotch}
      />
    </div>
  </div>
);

const CopyProgressModal = ({ programId }: { programId: number }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [copyProgressData, setCopyProgressData] = useState([]);
  const inProgressRef = useRef(null);
  const intervalRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Start polling
    intervalRef.current = setInterval(fetchData, POLL_INTERVAL);

    // Initial fetch
    fetchData();

    // Set up max timeout to stop polling
    timeoutRef.current = setTimeout(() => {
      clearInterval(intervalRef.current);
    }, MAX_TIMEOUT);

    return cleanUpIntervals;
  }, [setCopyProgressData]);

  const fetchData = () => {
    Axios.post(plato_api_copy_status_program_path(programId))
      .then(res => {
        const { data } = res.data;

        if (inProgressRef.current == null) {
          inProgressRef.current = data.length !== 0;
        }
        if (data.length === 0) {
          clearInterval(intervalRef.current);
        }

        setCopyProgressData(data);
      })
      .catch(e => {
        console.error(e);
        clearInterval(intervalRef.current);
      })
  };

  const cleanUpIntervals = () => {
    clearInterval(intervalRef.current);
    clearTimeout(timeoutRef.current);
  };

  const closeModal = () => {
    setIsOpen(false);
    cleanUpIntervals();
  };

  const copyComplete = inProgressRef.current && copyProgressData.length === 0;
  if (!isOpen || !inProgressRef.current || copyComplete) return null;

  return (
    <div className={`tw-fixed tw-right-3 tw-bottom-3 tw-w-[400px] tw-bg-white tw-rounded-lg
                     tw-z-[100] tw-shadow-custom`}
    >
      <div className={`tw-bg-tci-black tw-flex tw-text-white tw-px-5 tw-py-3 tw-items-center
                       tw-justify-between tw-rounded-t-lg tw-text-base`}
      >
        <div>{`Copying ${pluralize('lesson', copyProgressData.length, true)}`}</div>
        <div className="tw-flex tw-items-center tw-text-lg">
          <i
            className={cn(
              'fa tw-mr-4 tw-cursor-pointer',
              { 'fa-chevron-down': isExpanded, 'fa-chevron-up': !isExpanded }
            )}
            onClick={() => setIsExpanded(!isExpanded)}
          />
          <FontAwesomeIcon
            className="tw-cursor-pointer tw-text-[12px] tw-scale-x-[1.1]"
            icon={faX}
            onClick={closeModal}
          />
        </div>
      </div>

      <div className={cn(
        `tw-max-h-[250px] tw-overflow-y-auto tw-rounded-b-lg
         tw-border-b tw-border-l tw-border-r tw-border-solid tw-border-grey`,
        { 'tw-hidden': !isExpanded }
      )}>
        {copyProgressData.map((item, i) => (
          <CopyRow
            key={`copy-row-${i}`}
            item={item}
          />)
        )}
      </div>
    </div>
  );
};

export default CopyProgressModal;
