import React from 'react';
import I18n from 'i18n-js';

interface HeaderNumberBoxProps {
  number: number;
}

const HeaderNumberBox = ({ number }: HeaderNumberBoxProps) => (
  <div
    aria-label={`${I18n.t('section')} ${number}`}
    className="tw-inline-block tw-w-8 tw-h-8 tw-rounded-md tw-mr-2 tw-border tw-border-grey \
               tw-border-solid"
  >
    <p className="tw-m-0 tw-w-full tw-h-full tw-text-xl tw-align-middle tw-text-center tw-leading-8">{number}</p>
  </div>
);

export default HeaderNumberBox;
