import React from 'react';
import clsx from 'clsx';
import { CardType } from '@/types/api/LessonOverview';
import Tooltip from '@/components/common/Tooltip';
import useLessonOverviewContext from '@/components/shared/NewLessonOverview/useLessonOverviewContext';
import tooltipIndicatorIcon from 'images/icons/triangle-indicator.svg';
import handsOnIcon from 'images/icons/hands-on.svg';
import CardTitle from './CardTitle';

type CardBodyProps = {
  ariaLabel: string,
  iconSrc: string,
  linkHref: string,
  linkLabel: string,
  openInNewWindow?: boolean,
  page?: string,
  showThumbnail?: boolean,
  thumbnail?: string,
  time?: string,
  tooltipIndicator?: string,
  topLabel?: string,
};

export const CardBody = ({
  ariaLabel,
  iconSrc,
  linkHref,
  linkLabel,
  openInNewWindow,
  page,
  showThumbnail = false,
  thumbnail = '/placeholder.png',
  time,
  tooltipIndicator,
  topLabel,
}: CardBodyProps) => {
  const unitColor = useLessonOverviewContext(state => state.unitColor);
  const target = openInNewWindow ? '_blank' : '';

  return (
    <div
      className={clsx(
        'tw-h-fit tw-overflow-hidden tw-relative tw-flex tw-items-start tw-justify-start tw-flex-col', {
          'tw-p-2': showThumbnail,
          'tw-p-4': !showThumbnail,
        },
      )}
    >
      {!!tooltipIndicator && (
      <Tooltip content={tooltipIndicator}>
        <img
          alt={tooltipIndicator}
          className="tw-absolute tw-right-0 tw-top-0 tw-m-1"
          src={tooltipIndicatorIcon}
        />
      </Tooltip>
      )}
      {showThumbnail && (
        <img
          aria-hidden
          alt=""
          className="tw-h-[12rem] tw-w-full tw-object-cover tw-rounded-lg tw-mb-4"
          src={thumbnail}
        />
      )}
      <div className="tw-flex">
        <a
          aria-hidden
          aria-label={ariaLabel}
          className={clsx(
            'tw-border-none tw-rounded-xl tw-text-white tw-flex tw-items-center tw-justify-center tw-cursor-pointer', {
              'tw-w-12 tw-h-12 tw-absolute tw-top-6 tw-left-6': showThumbnail,
              'tw-w-20 tw-h-20': !showThumbnail,
            },
          )}
          href={linkHref}
          style={{ backgroundColor: `#${unitColor}` }}
          tabIndex={0}
          target={target}
        >
          <img
            alt={ariaLabel}
            className={showThumbnail ? 'tw-w-7 tw-h-7' : 'tw-w-12 tw-h-12'}
            src={iconSrc || handsOnIcon}
            style={{ filter: 'brightness(100)' }}
          />
        </a>
        <div>
          <CardTitle
            linkHref={linkHref}
            linkLabel={linkLabel}
            page={page}
            showThumbnail={showThumbnail}
            target={target}
            time={time}
            topLabel={topLabel}
          />
        </div>
      </div>
    </div>
  );
};

CardBody.defaultProps = {
  openInNewWindow: false,
};

type CardProps = {
  archived?: boolean,
  label: CardType,
  paddedLabel?: boolean,
  type: string,
  children: ReactChildren,
  showThumbnail?: boolean,
};

const Card = ({ archived = false, children, type, showThumbnail = false }: CardProps) => (
  <section
    aria-label={`${type}-card`}
    className={clsx(
      'card tw-relative tw-shrink-0 tw-w-96 tw-m-2 tw-rounded-lg',
      { archived },
      { 'tw-min-h-[23rem]': showThumbnail },
      { 'tw-max-h-[11rem]': !showThumbnail },
    )}
  >
    {children}
  </section>
);

export default Card;
