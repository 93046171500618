import React from 'react';
import I18n from 'i18n-js';
import { SectionType } from '@/types/api/LessonOverview';
import useLessonOverviewContext from '@/components/shared/NewLessonOverview/useLessonOverviewContext';
import { readingPath } from '@/components/shared/LessonOverview/utils';
import readingIcon from 'images/icons/reading-white.svg';
import Card, { CardBody } from './Card/Card';
import ActionBar from './ActionBar';

type ReadingCardProps = {
  sections: SectionType[];
};

const ReadingCard = ({ sections } : ReadingCardProps) => {
  const program = useLessonOverviewContext(state => state.program);

  return (
    <Card label="reading" type="reading">
      <CardBody
        ariaLabel="reading"
        iconSrc={readingIcon}
        linkHref={readingPath(null, program, sections)}
        linkLabel={I18n.t('reading')}
        page="-"
        time="-"
      />
      <ActionBar
        href={readingPath(null, program, sections)}
        iconType="arrow"
        initialValues={{
          modelType: 'sectionNotebook',
          modelsToAssign: sections.map(section => section.assignable.value),
        }}
        isAssigned={sections.some(section => section.isAssigned)}
      />
    </Card>
  );
};

export default ReadingCard;
