import React, { useEffect, useRef } from 'react';

interface ContentCheckboxProps {
  checked: boolean;
  disabled?: boolean;
  isIndeterminate?: boolean;
  onChange: () => void;
  label: string;
}

const ContentCheckbox = ({
  checked,
  disabled = false,
  isIndeterminate = false,
  label,
  onChange,
}: ContentCheckboxProps) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = isIndeterminate && !checked;
    }
  }, [checked, isIndeterminate]);

  return (
    <label className="tw-flex tw-items-center tw-mb-2">
      <input
        ref={ref}
        checked={checked}
        disabled={disabled}
        type="checkbox"
        onChange={onChange}
      />
      <span className="tw-ml-1">{label}</span>
    </label>
  );
};

export default ContentCheckbox;
