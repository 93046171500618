import React from 'react';
import * as Routes from '@/modules/routes';
import Card from '@/components/shared/NewLessonOverview/Cards/Card';
import { CardBody } from '@/components/shared/NewLessonOverview/Cards/Card/Card';
import ActionBar from '@/components/shared/NewLessonOverview/Cards/ActionBar';
import useLessonOverviewContext from '@/components/shared/NewLessonOverview/useLessonOverviewContext';
import icons from '@/components/admin/SlideShow/Utils/icons';
import Edit from './ActionBar/ActionBarButtons/SlideShowButtons/Edit';
import Copy from './ActionBar/ActionBarButtons/SlideShowButtons/Copy';

type SlideGroupCardProps = {
  slideGroup: any,
};

const ICON_TYPES = {
  Reading: icons.readingIcon,
  Vocabulary: icons.vocabIcon,
};

const SlideGroupCard = ({ slideGroup }: SlideGroupCardProps) => {
  const lessonId = useLessonOverviewContext(state => state.lessonId);
  const programId = useLessonOverviewContext(state => state.programId);
  const isStaffer = useLessonOverviewContext(state => state.isStaffer);
  const slideGroupPath = Routes.present_mode_shared_slide_show_path(slideGroup.slideShowId, {
    from: 'lesson',
    parent_id: lessonId,
    parent_type: 'Lesson',
    program_id: programId,
    starting_slide: slideGroup.firstSlideId,
  });

  const icon = ICON_TYPES[slideGroup.title];

  return (
    <Card
      label={slideGroup.title}
      type="slide_group"
    >
      <CardBody
        ariaLabel=""
        iconSrc={icons[slideGroup.icon] || icon}
        linkHref={slideGroupPath}
        linkLabel={slideGroup.title}
        page={slideGroup.page || '-'}
        time={slideGroup.pacing}
      />
      {isStaffer && (
        <ActionBar
          href={slideGroupPath}
          iconType="play"
          // initialValues={}
        >
          <Edit slideShow={slideGroup} />
          <Copy slideShow={slideGroup} />
        </ActionBar>
      )}
    </Card>
  );
};

export default SlideGroupCard;
