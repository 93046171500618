import React from 'react';
import { getNotebookPath } from '@/components/shared/LessonOverview/Cards/NotebookCards';
import useLessonOverviewContext from '@/components/shared/NewLessonOverview/useLessonOverviewContext';
import { Lesson, SectionType } from '@/types/api/LessonOverview';
import NotebookCard from './NotebookCard';

interface NotebookCardsProps {
  lesson: Lesson;
  sections: SectionType[];
}

const NotebookCards = ({ lesson, sections }: NotebookCardsProps) => {
  const program = useLessonOverviewContext(state => state.program);

  return (
    <>
      {sections.map(
        section => section.notebooks.map(notebook => (
          <NotebookCard
            key={notebook.id}
            fastTrack={notebook.fastTrack}
            notebookName={notebook.title}
            notebookPath={getNotebookPath(section, notebook, lesson, program)}
            section={section}
            type="notebook"
          />
        )),
      )}
    </>
  );
};

export default NotebookCards;
