import React from 'react';
import { grade_by_student_shared_question_answers_path } from '@/modules/routes';
import useGradesTableStore from '../../../store/useGradesTableStore';
import GradeCell from '.';
import useReadingGameExerciseResults from '../../../hooks/queries/exerciseResults/useReadingGameExerciseResults';
import useSectionNotebookExerciseResults from '../../../hooks/queries/exerciseResults/useSectionNotebookExerciseResults';
import useAssignmentQuestionAnswers from '../../../hooks/queries/questionAnswers/useAssignmentQuestionAnswers';
import { IExerciseResult } from '../../../types';
import { COLOR_LEGEND } from '../../../variables';
import { IAssignmentWithExtras } from '../../../hooks/queries/useSectionAssignments';
import useSubmissions from '../../../hooks/queries/useSubmissions';
import { ISubmission } from '@/components/interfaces';

const getStatus = (
  assignment: IAssignmentWithExtras,
  questionAnswers: ReturnType<typeof useAssignmentQuestionAnswers>['data'],
  exerciseResults: IExerciseResult[],
  submissions: ISubmission[],
): { color: string; text: string } => {
  let text = '-';
  const models = Object.values(assignment.assignment_models_hash).flat();

  let totalResultsCompleted = 0;

  exerciseResults.forEach((result) => {
    models.forEach((model) => {
      if (result.exercise_type !== model.assignment_type && result.exercise_id !== model.id) return;

      totalResultsCompleted += 1;
    });
  });

  if (totalResultsCompleted === models.length) {
    const submission = submissions.find(submission => submission.assignment_id === assignment.id);
    const score = submission?.total_score || submission?.total_score_override;

    text = score ? score.toString() : 'Turned In';

    return { color: COLOR_LEGEND.completed, text };
  }

  const { question_ids: questionIds } = assignment;

  if (questionAnswers.some(qa => questionIds.includes(qa.question_id))) {

    return { color: COLOR_LEGEND.in_progress, text: 'In Progress' };
  }

  return { color: '', text: '-' };
};

interface AssignmentGradeCellProps {
  assignment: IAssignmentWithExtras;
  studentId: number;
}

const AssignmentGradeCell = ({ assignment, studentId }: AssignmentGradeCellProps) => {
  const programId = useGradesTableStore(state => state.programId);
  const classroom = useGradesTableStore(state => state.classroom);
  const lesson = useGradesTableStore(state => state.lesson);

  const { data: readingGameResults } = useReadingGameExerciseResults();
  const { data: sectionNotebookExerciseResults } = useSectionNotebookExerciseResults();
  const { data: questionAnswers } = useAssignmentQuestionAnswers();
  const { data: submissions } = useSubmissions();

  const status = getStatus(
    assignment,
    questionAnswers,
    [...readingGameResults, ...sectionNotebookExerciseResults],
    submissions
  );

  const url = grade_by_student_shared_question_answers_path(
    studentId,
    { assignment_id: assignment.id, classroom_id: classroom.id, lesson_id: lesson.id, program_id: programId },
  );

  return (
    <GradeCell
      id={`assignment-${assignment.id}-student-score`}
      status={status}
      url={url}
    />
  );
};

export default AssignmentGradeCell;
