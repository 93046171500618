import React from 'react';
import I18n from 'i18n-js';
import useLessonOverview from '@/hooks/api/useLessonOverview';
import Header from '@/components/shared/NewLessonOverview/Header';
import AssessWith from '@/components/shared/NewLessonOverview/AssessWith';
import useLessonOverviewContext from '@/components/shared/NewLessonOverview/useLessonOverviewContext';
import NewLessonOverviewStoreProvider from '@/components/shared/NewLessonOverview/NewLessonOverviewStoreProvider';
import { NewLessonOverviewProps } from './types';
import DiveDeeper from './DiveDeeper';
import Libraries from './Libraries';
import LessonOptions from './LessonOptions';
import LessonSubheader from './LessonSubheader';
import SlideGroupCard from './Cards/Card/SlideGroupCard';

const NewLessonOverview = () => {
  const program = useLessonOverviewContext(state => state.program);
  const lesson = useLessonOverviewContext(state => state.lesson);
  const slideShows = useLessonOverviewContext(state => state.slideShows);
  const sections = useLessonOverviewContext(state => state.sections);

  const filteredSlideGroups = (displayLocation: string) => (
    (slideShows?.tci?.first?.slideGroups ?? []).filter(slideGroup => (
      slideGroup.displayLocationInLessonOverview === displayLocation
    ))
  );

  const introSlideGroups = filteredSlideGroups('introduce_the_lesson');
  const lessonOptionSlideGroups = filteredSlideGroups('lesson_options');
  const finalSlideGroups = filteredSlideGroups('put_it_all_together');

  // Tracks *visible* subsections so we know what to number them as in the page structure
  // Matches the conditions used to render each subsection
  const subsectionNumberMap = [
    'header',
    introSlideGroups.length > 0 ? 'introduce_the_lesson' : null,
    slideShows ? 'lesson_options' : null,
    program ? 'assess_with' : null,
    finalSlideGroups.length > 0 ? 'put_it_all_together' : null,
  ].filter(Boolean);

  return (
    <>
      <Header />
      {introSlideGroups.length > 0 && (
        <LessonSubheader
          subheaderNumber={subsectionNumberMap.indexOf('introduce_the_lesson')}
          title={I18n.t('introduce_the_lesson')}
        >
          <section className="tw-w-full tw-pr-16">
            {introSlideGroups.map(slideGroup => (
              <SlideGroupCard
                icon="eye"
                slideGroup={slideGroup}
              />
            ))}
          </section>
        </LessonSubheader>
      )}
      <LessonOptions
        sections={sections}
        slideGroups={lessonOptionSlideGroups}
        slideShows={slideShows}
        subheaderNumber={subsectionNumberMap.indexOf('lesson_options')}
      />
      <div>
        <AssessWith
          lesson={lesson}
          sections={sections}
          subheaderNumber={subsectionNumberMap.indexOf('assess_with')}
        />
      </div>

      {finalSlideGroups.length > 0 && (
        <LessonSubheader
          subheaderNumber={subsectionNumberMap.indexOf('put_it_all_together')}
          title={I18n.t('put_it_all_together')}
        >
          <section className="tw-w-full tw-pr-16">
            {finalSlideGroups.map(slideGroup => (
              <SlideGroupCard icon="pencil" slideGroup={slideGroup} />
            ))}
          </section>
        </LessonSubheader>
      )}

      <footer className="tw-w-full tw-h-[300px] tw-bg-light-grey tw-flex">
        <DiveDeeper />
        <Libraries />
      </footer>
    </>
  );
};

const NewLessonOverviewWrapper = (props: NewLessonOverviewProps) => {
  const { programId, lessonId, locale } = props;
  const lessonOverviewData = useLessonOverview({ lessonId, locale, programId });

  if (lessonOverviewData.isLoading) return null;

  return (
    <NewLessonOverviewStoreProvider
      props={{ ...props, ...lessonOverviewData }}
    >
      <NewLessonOverview />
    </NewLessonOverviewStoreProvider>
  );
};

export default NewLessonOverviewWrapper;
