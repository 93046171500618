import useAssessmentQuestionAnswers from './queries/questionAnswers/useAssessmentQuestionAnswers';
import useVideoActivityQuestionAnswers from './queries/questionAnswers/useVideoActivityQuestionAnswers';
import useGradesTableStore from '../store/useGradesTableStore';
import useGames from './queries/exerciseResults/useGames';
import useLessonGames from './queries/useLessonGames';
import useLessonNotebooks from './queries/exerciseResults/useLessonNotebooks';
import useVideoActivityExerciseResult from './queries/exerciseResults/useVideoActivityExerciseResult';
import useSectionNotebookExerciseResults from './queries/exerciseResults/useSectionNotebookExerciseResults';
import useScorableNotebooks from './queries/useScorableNotebooks';
import useAssessmentExerciseResults from './queries/exerciseResults/useAssessmentExerciseResults';
import useAssessments from './queries/useAssessments';
import useClassroomLesson from './queries/useClassroomLesson';
import useUnitProjects from './queries/useUnitProjects';
import useUnitProjectExerciseResults from './queries/exerciseResults/useUnitProjectExerciseResults';
import useStudents from './queries/useStudents';
import useClassroom from './queries/useClassroom';
import useProgramAccess from './queries/useProgramAccess';
import useReadingGameExerciseResults from './queries/exerciseResults/useReadingGameExerciseResults';
import useReferenceMaterialExerciseResults from './queries/exerciseResults/useReferenceMaterialExerciseResults';
import useReadingGames from './queries/useReadingGames';
import useSlideShows from './queries/useSlideShows';
import useReferenceMaterials from './queries/useReferenceMaterials';
import useSectionNotebooksQuestionAnswers from './queries/questionAnswers/useSectionNotebooksQuestionAnswers';
import useSectionAssignments from './queries/useSectionAssignments';
import useAssignmentQuestionAnswers from './queries/questionAnswers/useAssignmentQuestionAnswers';
import useSubmissions from './queries/useSubmissions';

const useGradesTableData = () => {
  const classroom = useGradesTableStore(state => state.classroom);
  const lesson = useGradesTableStore(state => state.lesson);

  useStudents(classroom?.id);
  useClassroom();
  useAssessments(classroom.id, lesson?.id);
  useAssessmentQuestionAnswers();
  useAssignmentQuestionAnswers();
  useLessonNotebooks();
  useSectionNotebookExerciseResults();
  useScorableNotebooks();
  useGames();
  useSectionAssignments();
  useReferenceMaterials(classroom.id, lesson?.id);
  useLessonGames();
  useReadingGames(lesson?.id);
  useReadingGameExerciseResults();
  useSlideShows(classroom.id, lesson?.id);
  useClassroomLesson(classroom.id, lesson?.id);
  useAssessmentExerciseResults(classroom, lesson);
  useReferenceMaterialExerciseResults(classroom, lesson);
  useVideoActivityExerciseResult(classroom, lesson);
  useVideoActivityQuestionAnswers();
  useUnitProjects();
  useUnitProjectExerciseResults();
  useProgramAccess();
  useSectionNotebooksQuestionAnswers();
  useSubmissions();
};

export default useGradesTableData;
