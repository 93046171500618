import { useEffect, useState } from 'react';
import axios from 'axios';
import * as Routes from '@/modules/routes';
import { StafferSlideShows, Lesson, Program, SectionType } from '@/types/api/LessonOverview';

type UseLessonOverviewProps = {
  programId: number,
  lessonId: number,
  locale: string,
};

export interface LessonOverviewResponse {
  lesson: Lesson;
  program: Program;
  sections: SectionType[];
  slideShows: StafferSlideShows;
  isLoading: boolean;
}

const useLessonOverview = ({
  lessonId,
  locale,
  programId,
}: UseLessonOverviewProps): LessonOverviewResponse => {
  const [lesson, setLesson] = useState<Lesson>(null);
  const [sections, setSections] = useState<SectionType[]>([]);
  const [slideShows, setSlideShows] = useState<StafferSlideShows>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [program, setProgram] = useState<Program>(null);

  const lessonOverviewPath = Routes.plato_api_program_lesson_overview_index_path(
    programId, lessonId, { locale },
  );

  useEffect(() => {
    axios.get(lessonOverviewPath)
      .then((response) => {
        setLesson(response.data.lesson);
        setProgram(response.data.program);
        setSections(response.data.sections);
        setSlideShows(response.data.slideShows);
      }).finally(() => setIsLoading(false));
  }, []);

  return { isLoading, lesson, program, sections, slideShows };
};

export default useLessonOverview;
