import { ReferenceMaterialTypes } from '@/components/interfaces/ReferenceMaterial';
import I18n from 'i18n-js';
import { capitalizeWords } from '@/modules/TCIUtils';
import { getSectionsByKind } from '@/components/shared/LessonOverview/utils';

const LIBRARIES_CONFIG_BY_TYPE = {
  [ReferenceMaterialTypes.BIOGRAPHIES_TYPE]: {
    i18Key: 'library_biographies',
    tag: 'Biographies',
  },
  [ReferenceMaterialTypes.CAREER_PROFILES_TYPE]: {
    i18Key: 'library_career_profiles',
    tag: 'Career Profiles',
  },
  [ReferenceMaterialTypes.SUPREME_COURT_CASES_TYPE]: {
    i18Key: 'library_supreme_court_cases',
    tag: 'Supreme Court Cases',
  },
  [ReferenceMaterialTypes.PRIMARY_SOURCES_TYPE]: {
    i18Key: 'library_primary_sources',
    tag: 'Primary Source Library',
  },
  [ReferenceMaterialTypes.CIVICS_TYPE]: {
    i18Key: 'library_civics',
    tag: 'Civics Library',
  },
  [ReferenceMaterialTypes.MAPS_TYPE]: {
    i18Key: 'library_maps',
    tag: 'Maps',
  },
  [ReferenceMaterialTypes.SCIENCE_AND_ENGINEERING_TYPE]: {
    i18Key: 'library_science_and_engineering',
    tag: 'Science and Engineering Resources',
  },
};

export const referenceMaterialLabel = type => LIBRARIES_CONFIG_BY_TYPE[type] || {};

export const readingLabel = (type) => {
  const label = type === 'biography' ? 'profiles_in_history' : type;
  const allCaps = capitalizeWords(I18n.t(label.replace(/ /g, '_')));

  return allCaps.replace(' The ', ' the ').replace(' To ', ' to ').replace(' In ', ' in ');
};

export const diveDeeperFormat = (lesson, sections) => {
  const sectionsByKind = getSectionsByKind(sections);

  const readingTypes = Object.keys(sectionsByKind).filter(kind => (
    !['default', 'unit projects', 'key science concept'].includes(kind)
  ));

  return {
    readings: readingTypes.flatMap(type => sectionsByKind[type]),
    referenceMaterials: lesson?.referenceMaterialAssignments || [],
  };
};
