import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { ISubmission } from '@/components/interfaces';
import { plato_api_submissions_path } from '@/modules/routes';
import useGradesTableStore from '../../store/useGradesTableStore';
import { gradingByAssignment } from '../../Utils';
import useClassroomLesson from './useClassroomLesson';
import useSectionAssignments from './useSectionAssignments';

interface IApiResponse {
  data: ISubmission[];
}

const useSubmissions = () => {
  const lesson = useGradesTableStore(state => state.lesson);
  const classroom = useGradesTableStore(state => state.classroom);
  const { data: classroomLesson } = useClassroomLesson(classroom.id, lesson?.id);
  const { data: assignments } = useSectionAssignments();

  const isEnabled = !!(
    lesson?.id
    && classroom?.id
    && gradingByAssignment(classroomLesson)
    && assignments.length > 0
  );

  const route = plato_api_submissions_path({
    for_assignments: assignments.map(a => a.id),
  });

  return useQuery(['useSubmissions', lesson?.id], () => (
    axios
      .get<IApiResponse>(route)
      .then(res => res.data.data)
      .catch(() => ([] as ISubmission[]))
  ), { enabled: isEnabled, placeholderData: [] });
};

export default useSubmissions;
