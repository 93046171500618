import React from 'react';
import ReadingSelectModal from './ReadingSelectModal';

interface SlideObjectReadingProps {
  interactable: boolean;
  modalManager: ModalManager;
  slideObject: {
    id: string;
    htmlEn: any;
  }
}

const SlideObjectReading = ({ interactable, modalManager, slideObject }: SlideObjectReadingProps) => {
  return (
    <>
      <div
        dangerouslySetInnerHTML={{ __html: slideObject.htmlEn }}
      />
      {interactable && (
        <ReadingSelectModal modalManager={modalManager} slideObjectId={slideObject.id} />
      )}
    </>
  );
};

export default SlideObjectReading;
