import React from 'react';
import I18n from 'i18n-js';
import arrowIcon from 'images/icons/arrow.svg';
import { twMerge } from '@/utils';
import Tooltip from '@/components/common/Tooltip';
import playIcon from 'images/icons/present.svg';

interface GoToButtonProps {
  href: string;
  iconType?: 'play' | 'arrow';
  label?: string;
  extraClassName?: string;
}

export const CIRCLE_ICON_CLASS = twMerge(
  'tw-border-none tw-rounded-[50%] tw-text-2xl',
  'tw-flex tw-justify-center tw-items-center',
  'tw-cursor-pointer tw-mr-[10px] tw-h-11 tw-w-11',
);

const GoToButton = ({ href, iconType, label, extraClassName }: GoToButtonProps) => {
  const icon = iconType === 'play' ? playIcon : arrowIcon;
  const tooltipShouldDisplay = iconType === 'play';

  return (
    <Tooltip
      hideOnClick
      content={I18n.t('present_slides_card')}
      distance="15"
      shouldDisplay={tooltipShouldDisplay}
      size="medium"
    >
      <a
        aria-label={I18n.t('go_to', { name: label })}
        className={twMerge(CIRCLE_ICON_CLASS, 'tw-bg-tci-purple', extraClassName)}
        href={href}
        target={iconType === 'play' ? '_blank' : ''}
      >
        <img aria-hidden alt="" src={icon} />
      </a>
    </Tooltip>
  );
};

export default GoToButton;
